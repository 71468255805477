@import '../constants.scss';

.transactions-container {
  flex: 1;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 70px;
  background-color: var(--grey-background);
}

.transactions-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-transactions {
  display: flex;
  flex: 0 0 85px;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.header-list-title {
  color: #000000;
  filter: var(--filter-white);
  font-family: NunitoSans-Bold;
  font-size: 23px;
  font-weight: bold;
  height: 31px;
  letter-spacing: 0px;
  text-align: center;
  width: 100px;
}

.header-list {
  height: 85px;
}