@import './constants.scss';

.header-container {
  display: flex;
  height: 80px;
  width: 100%;
}

.logos {
  display: flex;
  justify-content: space-between;
}

.logo-tns {
  width: 3.5rem;
  filter: var(--filter-white);
}

.logo-marquee {
  width: 3.5rem;
  filter: var(--filter-white);
}

.header-title {
  font-size: 21px;
  font-family: 'NunitoSans-Bold';
  color: #000000 !important;
  filter: var(--filter-white);
}

.user-container {
  display: flex;
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  min-width: 17rem;
}

.user-information {
  display: flex;
  flex-direction: column;
}

.booking-image {
  cursor: pointer;
  width: 3rem;
  filter: var(--filter-primary);
}

.user-name {
  color: var(--color-primary);
  font-size: 14px;
  font-family: 'NunitoSans-Black';
  letter-spacing: 0px;
  white-space: nowrap;
}

.user-rank {
  color: var(--color-primary);
  font-size: 12px;
  letter-spacing: 0px;
}

.user-points {
  color: var(--color-primary);
  font-size: 12px;
  letter-spacing: 0px;
}

.burger-icon {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  filter: var(--filter-white);
}

.notification-icon {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  filter: var(--filter-white);
  margin-right: 7px;
}

.messages-icon-container {
  margin-right: -7px;
}

.burger-icon-container {
  margin-left: -7px;
}

.new-notification {
  position: absolute;
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  left: 0;
  font-size: 10px;
  margin-left: 92%;
  margin-top: 30px;
}

.new-notification-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: white;
}

.impersonation-badge {
  font-size: 17px;
  border: 1px solid;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  z-index: 1000;
}

@media screen and (max-width: 325px) {
  .header-container {
    height: 75px;
  }

  .logo-tns {
    width: 3rem;
    height: 3rem;
  }

  .logo-marquee {
    width: 3rem;
    height: 3rem;
  }

  .new-notification {
    margin-top: 25px;
    margin-left: 91%;
  }
}

@media screen and (min-width: 410px) {
  .new-notification {
    margin-top: 30px;
    margin-left: 93%;
  }
}
