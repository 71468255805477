:root {
  --color-primary: #724467;
  --color-level1-dark: #5E3554;
  --color-level1-darker: #5D2F51;

  --color-level2: #98458C;
  --color-level2-dark: #7D3A73;
  --color-level2-darker: #7C3972;

  --color-level3: #C25281;
  --color-level3-dark: #B54976;
  --color-level3-darker: #B54976;

  --color-level4: #D88282;
  --color-level4-dark: #D17474;
  --color-level4-darker: #B65555;

  --color-cancel: #D86354;
  --color-green: #67C08C;
  --color-yellow: #E5B363;
  --color-grey: #B1BCCE;
  --color-expired: #77838F;
  --color-expired-darken: #626161;
  --color-info: #679EC0;

  --color-anti-flash-white: #f2eef5;
  --color-grey-image:#656262eb;
  --grey-background: #FAFAFAFA;
  --grey-font: #3F3F3F;
  --grey-font-light-icons: #858b94;
  --grey-dividers: #DCDDDE;

  --gradient-level1: linear-gradient(-45deg, #724467 0%, #8A547D 100%);
  --gradient-level2: linear-gradient(-45deg, #98458C 0%, #B756A9 100%);
  --gradient-level3: linear-gradient(-45deg, #C25281 0%, #E87CA9 100%);
  --gradient-level4: linear-gradient(-45deg, #F28787 0%, #D29898 100%);

  --filter-primary: invert(28%) sepia(15%) saturate(1481%) hue-rotate(262deg) brightness(97%) contrast(84%);
  --filter-lavanda: invert(78%) sepia(5%) saturate(818%) hue-rotate(175deg) brightness(97%) contrast(88%);
  --filter-dark-grey: invert(26%) sepia(6%) saturate(0%) hue-rotate(272deg) brightness(86%) contrast(92%);
  --filter-grey: invert(33%) sepia(0%) saturate(22%) hue-rotate(191deg) brightness(117%) contrast(90%);
  --filter-red: invert(66%) sepia(36%) saturate(6357%) hue-rotate(325deg) brightness(90%) contrast(87%);
  --filter-green: invert(55%) sepia(52%) saturate(557%) hue-rotate(93deg) brightness(90%) contrast(98%);
  --filter-white: invert(97%) sepia(0%) saturate(7467%) hue-rotate(283deg) brightness(105%) contrast(99%);
  --filter-transparent: invert(100%) sepia(100%) saturate(3%) hue-rotate(126deg) brightness(108%) contrast(100%);
  --filter-yellow: invert(100%) sepia(29%) saturate(5366%) hue-rotate(311deg) brightness(93%) contrast(93%);
  --filter-expired: invert(53%) sepia(10%) saturate(471%) hue-rotate(169deg) brightness(95%) contrast(90%);
}
