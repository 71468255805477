.lockServiceProviders {
  padding-top: 20px;

  &__help {
    text-align: center;
    padding: 0 10px 0 10px;
    font-weight: bold;
  }

  &__services {
    margin-top: 20px;
    width: 100%;

    &__service {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 10px;
      }

      &--name {
        padding-left: 20px;
        font-weight: bold;
      }

      &__provider {
        display: flex;
        align-items: center;
        justify-content: left;

        &--select {
          padding-top: 10px;
        }

        &--lockButton {
          margin-left: auto;

          button {
            font-size: 14px;
          }
        }

        &--icon {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        &--desc {
          margin-left: 20px;
          display: block;

          span {
            color: var(--color-grey);
            font-style: italic;
            font-size: 14px;
          }
        }

        &--tenure-title {
          font-weight: 800;
        }

        &--served-on,
        &--rating,
        &--tenure-date {
          font-weight: 300;
        }
      }
    }
  }
}

// OVERRIDE STAR RATING

.lockServiceProviders .star-icon {
  width: 1rem;
  height: 1rem;
}
