.stars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.stars-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.star-icon {
  width: 1.6rem;
  height: 1.6rem;
}