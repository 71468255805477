.booking-card-tile-parent {
  display: flex;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  background-color:white;
  align-items: center;
  justify-content: center;
}

.booking-card-tile {
  display: flex;
  box-shadow: 0 0 8px 0 rgba(177,188,206,0.45);
  border: 0px solid var(--grey-background);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  height: 100%;
}

.booking-card-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  justify-content: space-around;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  color: var(--grey-font);
}

.booking-card-image {
  width: 30%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.booking-details-icon {
  filter: var(--filter-grey);
  width: 1.5rem;
  height: 1.5rem;
}

.booking-status-stripe {
  height: 100%;
  width: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.booking-card-text {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
  color: var(--grey-font);
}

@media screen and (max-width: 350px) {
  .booking-card-tile-parent {
    height: 80px;
  }

  .booking-card-details {
    font-size: 13px;
  }
}
