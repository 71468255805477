@import '../constants.scss';

.dashboard-card-tile {
  display: flex;
  min-height: 85px;
  align-items: center;
}

.dashboard-card-tile-separator {
  border-bottom: 1px solid var(--grey-dividers);
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-tile-icon {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: var(--grey-background);
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  position: relative;
}

.card-tile-icon-img {
  width: 4rem;
  height: 4rem;
  filter: var(--filter-grey);
}

.card-tile-icon-img-checkmark {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  filter: var(--filter-green);
}

.card-tile-icon-img-pending {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  filter: var(--filter-yellow);
}

.card-tile-icon-img-cancel {
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 0;
  right: 5%;
  filter: var(--filter-red);
}

.card-tile-icon-img-closed {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 60%;
  filter: var(--filter-grey);
}

.card-tile-icon-img-no-show {
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 10%;
  right: 7%;
  filter: var(--filter-grey);
}

.vertical-separator {
  border-left: 1px solid var(--grey-dividers);
  padding-right: 10px;
  display: inline-block;
  height: 60px;
}

.dashboard-card-amount-text {
  white-space: pre-line;
  color: var(--grey-font);
  font-family: 'NunitoSans-SemiBold';
  font-size: 18px;
}

.dashboard-card-right-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-right: 0;
  padding-left: auto;
}

.right-menu-icon {
  filter: var(--filter-primary);
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 350px) {
  .card-tile-icon {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
  }

  .card-tile-icon-img {
    width: 3rem;
    height: 3rem;
    position: relative;
  }

  .card-tile-icon-img-checkmark {
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    right: 5%;
  }

  .card-tile-icon-img-pending {
    width: 1.3rem;
    height: 1.3rem;
  }

  .card-tile-icon-cancel {
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    top: 0;
    right: 5%;
  }

  .card-tile-icon-img-closed {
    width: 1.5rem;
    height: 1.5rem;
  }

  .card-tile-icon-img-no-show {
    width: 0.8rem;
    height: 0.8rem;
  }

  .dashboard-card-amount-text {
    font-size: 14px;
  }
}
