@import '../constants.scss';

.services-cards {
  overflow: scroll;
  scrollbar-width: none;
}

.service-card {
  background: rgb(250, 250, 250);
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  margin-bottom: 10px;
  width: 100%;
  position: relative;

  &-read-only {
    opacity: 0.6;
  }
}

.service-card-header-top {
  border-top: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-card-header {
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-category-name {
  font-size: 14px;
  font-family: 'NunitoSans-Bold';
  padding: 8px 10px;
  margin: 0;
  color: var(--grey-font-light-icons);
}

.service-card-body {
  padding: 8px 10px 0px;
}

.service-name {
  font-size: 18px;
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-providers-list {
  overflow: scroll;
  scrollbar-width: none;
}

.resource-type-icon {
  width: 40px;
  height: 40px;
}

.service-resource-type {
  width: 100%;
  color: var(--grey-font-light-icons);
  font-size: 16px;
  align-self: center;
}

.service-length {
  color: var(--grey-font-light-icons);
  font-size: 16px;
  align-self: center;
}

.service-length-icon {
  width: 40px;
  height: 40px;
}

.selected-provider-img {
  width: 65px;
  height: 65px;
  background-color: #03030314;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
  margin-right: 15px;
}

.selected-provider-name {
  color: var(--grey-font-light-icons);
  font-size: 16px;
  font-weight: 500;
}

.selected-light-grey {
  color: var(--grey-font-light-icons);
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
}

.selected-provider-delay {
  color: rgb(192, 57, 43);
  font-size: 16px;
}

.reset-button-service-provider {
  margin-right: 10px;
}

.selected-provider-actions {
  flex-direction: row;
}

@media(max-width: 550px) {
  .selected-provider-actions {
    flex-direction: column;
  }

  .reset-button-service-provider {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

// OVERRIDE RATING STARS

.service-providers-list .star-icon {
  width: 1rem;
  height: 1rem;
}

.service-providers-container {
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
}

.service-providers-title {
  align-self: center;
  color: var(--grey-font-light-icons);
  font-size: 18px;
  padding-bottom: 20px;
}

.close-providers-icon {
  width: 40px;
  height: 40px;
  align-self: flex-end;
  cursor: pointer;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.provider {
  border-bottom: 1px solid #dddddd;
  position: relative;
}

.locked {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #dddddd;
  height: 4.6rem;
  opacity: 0.5;
  z-index: 1;
}

.provider-img {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
  margin-right: 15px;
}

.provider-name {
  color: var(--grey-font-light-icons);
  font-size: 16px;
}

.provider-delay {
  color: rgb(192, 57, 43);
  font-size: 14px;
}

.provider-service-length-icon {
  width: 22px;
  height: 22px;
  margin-left: -5px;
  margin-top: -2px;
}

.provider-service-length {
  color: var(--grey-font-light-icons);
  font-size: 14px;
  font-style: normal;
}

.provider-service-current-service {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: normal;

  svg {
    margin-right: 5px;
  }
}

.provider-service-delay-icon {
  filter: var(--filter-primary);
  width: 22px;
  height: 22px;
}

.selected-provider-delay-icon {
  filter: var(--filter-primary);
  width: 28px;
  height: 28px;
  margin-left: -5px;
  margin-top: -3px;
}

.selected-provider-start-time {
  margin-right: 5px;
  color: var(--grey-font-light-icons);
}

.change-button {
  border-radius: .1875rem;
  min-width: 100px;
  height: 65px;
  align-self: center;
  color: white;
  transition: .2s;
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

.change-button-service-provider,
.reset-button-service-provider {
  border-radius: .1875rem;
  color: white;
  transition: .2s;
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
  width: 100px;
  height: 45px;
}

.change-button-disable {
  background-color: var(--grey-background);
  border-radius: .1875rem;
  border: .0625rem solid #C6C6C6;
  min-width: 100px;
  color: #b9b8b8;
  transition: .2s;
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

@media screen and (max-width: 350px) {
  .service-name {
    font-size: 16px;
    color: var(--color-primary);
    font-family: 'NunitoSans-Bold';
  }

  .service-length {
    color: var(--grey-font-light-icons);
    font-size: 15px;
    align-self: center;
  }

  .service-resource-type {
    width: 100%;
    color: var(--grey-font-light-icons);
    font-size: 15px;
    align-self: center;
  }

  .resource-type-icon {
    width: 35px;
    height: 35px;
  }

  .service-length-icon {
    width: 35px;
    height: 35px;
  }

  .selected-provider-name {
    color: var(--grey-font-light-icons);
    font-size: 14px;
    font-weight: 500;
  }

  .change-button-service-provider {
    font-size: 16px;
    width: 95px;
  }

  .change-button {
    height: 50px;
  }
}


.grey-divider {
  border-bottom: 1px solid #dddddd;
}