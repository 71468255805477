.password-icon-new {
  position: absolute;
  top: 14px;
  right: 17px;
}

.current-error{
  color:red;
  font-size: 10px;
  position: relative;
}