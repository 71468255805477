.component-header {
  display: flex;
  align-self: center;
  width: calc(100% - var(--header-card-width));
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
}

.component-title-section {
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
}

.component-header-route-text {
  color: var(--grey-font-light-icons);
  font-size: 14px;
}

.component-header-right-icon {
  filter: var(--filter-lavanda);
  width: 1.6rem;
  height: 1.6rem;
  margin-right: -7px;
  margin-bottom: 1px;
  cursor: pointer;
}

@media screen and (max-width: 350px) {
  .component-header {
    padding-bottom: 10px;
  }

  .component-title-section {
    font-size: 16px;
  }
}