.soak_off {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  padding-top: 1em;

  &_title {
    margin-bottom: 1.5em;
  }

  &_box {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;
    margin-bottom: 1em;
    padding: 1em;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgb(250, 250, 250);

    &--active {
      border-width: 1px;
      border-style: solid;
      box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1), 0px 2px 20px 0px rgba(94, 53, 84, 0.1);
    }

    &_label {
      margin-left: 1.5em;
    }
  }
}
