.service-type-container {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 10px;
  align-items: center;
  cursor: pointer;
  background: var(--grey-background);
  border-radius: 3px;
  min-height: 85px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
}

.service-type-information-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.service-type-name {
  cursor: pointer;
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

.service-type-description {
  cursor: pointer;
  color: var(--grey-font-light-icons);
  font-size: 14px;
}

.service-type-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 10px;
}

.service-type-container-selected {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 10px;
  align-items: center;
  cursor: pointer;
  background: var(--grey-background);
  border-radius: 3px;
  min-height: 85px;
  margin-bottom: 10px;
  box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1),
  0px 2px 20px 0px rgba(94, 53, 84, 0.1);
}

@media screen and (max-height: 740px) {
  .service-type-container {
    display: flex;
    width: 100%;
    padding: 0px 20px 0px 10px;
    align-items: center;
    cursor: pointer;
    background: var(--grey-background);
    border-radius: 3px;
    min-height: 70px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
  }
}

.service-type-container-selected-lvl1 {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 10px;
  align-items: center;
  cursor: pointer;
  background: var(--grey-background);
  border-radius: 3px;
  min-height: 70px;
  margin-bottom: 10px;
  box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1),
  0px 2px 20px 0px rgba(94, 53, 84, 0.1);
  border: 1px solid var(--color-primary);
}

.service-type-container-selected-lvl2 {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 10px;
  align-items: center;
  cursor: pointer;
  background: var(--grey-background);
  border-radius: 3px;
  min-height: 70px;
  margin-bottom: 10px;
  box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1),
  0px 2px 20px 0px rgba(94, 53, 84, 0.1);
  border: 1px solid var(--color-level2);
}

.service-type-container-selected-lvl3 {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 10px;
  align-items: center;
  cursor: pointer;
  background: var(--grey-background);
  border-radius: 3px;
  min-height: 70px;
  margin-bottom: 10px;
  box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1),
  0px 2px 20px 0px rgba(94, 53, 84, 0.1);
  border: 1px solid var(--color-level3);

}

.service-type-container-selected-lvl4 {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 10px;
  align-items: center;
  cursor: pointer;
  background: var(--grey-background);
  border-radius: 3px;
  min-height: 70px;
  margin-bottom: 10px;
  box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1),
  0px 2px 20px 0px rgba(94, 53, 84, 0.1);
  border: 1px solid var(--color-level4);

}
