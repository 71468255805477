.privacy-policy {
  scrollbar-width: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../img/sign_in_background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  &-content {
    p {
      margin-bottom: 20px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 70%;
    height: calc(100% - calc(1.5 * constant(safe-area-inset-bottom)));
    height: calc(100% - calc(1.5 * env(safe-area-inset-bottom)));
    margin-left: auto;
    margin-right: auto;
    color: white;
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);

    &-title-1 {
      font-size: 1.8em;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
      display: flex;
      width: 100%;
    }

    &-title-1 .centered-button{
      margin-top: auto;
      margin-bottom: auto;
    }

    &-title-1 .privacy-title {
      width: 100%;
    }

    &-title-2 {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-toc {
      list-style: ordered;
      margin-left: 20px;
      margin-bottom: 20px;


    }

    a:link {
      text-decoration: underline;
    }
  }
}
