.settings-email-content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--grey-background);
}

.settings-email-options {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 24px 24px 0;
  margin-bottom: 150px;
}

.settings-email-option {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  &-header {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
  }

  &-icon {
    width: 24px;
    height: 24px;
  }

  &-title {
    font-size: 16px;
    font-family: "NunitoSans-Bold";
    font-weight: bold;
  }

  &-text {
    width: 100%;
    text-align: justify;
    font-size: 16px;
    font-family: "NunitoSans-Regular";
    color: var(--grey-font);
  }
}


@media screen and (max-width: 350px) {
  .settings-email-option {
    &-icon {
      width: 20px;
      height: 20px;
    }

    &-title {
      font-size: 14px;
    }

    &-text {
      font-size: 14px;
    }
  }

  .toggle-switch {
    width: 42px;
    height: 20px;

    &-switch {
      width: 16px;
      height: 16px;
    }
  }
}
