.stamp-card-details {
  &-header {
    position: absolute;
    top: constant(safe-area-inset-bottom);
    top: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    padding: 30px 0 10px 0;
    z-index: 4;

    &-back-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: 'NunitoSans-Bold';
      color: white;
      padding: 2px 10px;
      border-radius: 4px;
    }
  }

  &-top-half {
    position: relative;
    height: 50%;
    width: 100%;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding: 60px 10% 15% 5%;
      text-overflow: ellipsis;
      z-index: 2;

      &-name {
        font-family: "NunitoSans-Bold";
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0px;
        text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
        color: white;
      }

      &-description {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0;
        color: white;
      }
    }
  }

  &-image {
    position: absolute;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(81, 42, 72, 0.25) none no-repeat scroll 0% 0%;
    mix-blend-mode: multiply;
  }

  &-bottom-half {
    position: relative;
    box-shadow: inset 0px 2px 51px 0px rgba(0, 0, 0, 0.5);
    height: 50%;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
  }

  &-middle {
    --stamp-card-details-stamp-width: 80px;
    position: fixed;
    width: var(--stamp-card-details-stamp-width);
    height: var(--stamp-card-details-stamp-width);
    left: 0;
    right: 0;
    top: calc(50% - (var(--stamp-card-details-stamp-width) / 2));
    margin: 0 auto;
    z-index: 2;
    border-radius: 50%;
  }

  &-middle-icon {
    width: 100%;
    height: 75%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &-stamps-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &-stamps-grid {
    position: relative;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
    grid-row-gap: .5rem;
    margin: auto auto 0 auto;
    padding: 40px 0;
  }

  &-circle {
    display: flex;
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
  }

  &-circle-stamped {
    position: relative;
    width: 80%;
    height: 80%;
    margin: auto;
  }

  &-circle-last {
    width: 60%;
    height: 60%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &-button-wrapper {
    padding-bottom: 50px;
  }

  &-button {
    display: block;
    width: 70%;
    border-radius: 4px;
    background-color: white;

    font-size: 22px;
    font-family: "NunitoSans-Bold";
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

  }
}

@media screen and (max-width: 325px) {
  .stamp-card-details {
    &-circle {
      width: 60px;
      height: 60px;
    }

    &-middle {
      --stamp-card-details-stamp-width: 60px;
    }

    &-top-half {
      &-content {
        &-name {
          font-size: 20px;
        }

        &-description {
          font-size: 14px;
        }
      }
    }
  }
}
