.react-datepicker {
  border: none;
  border-radius: unset;
  font-family: 'NunitoSans-Regular';
}

.tab .react-datepicker {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: unset;
  font-family: 'NunitoSans-Regular';
}

.react-datepicker__header {
  background-color: white;
  border: none;
}

.react-datepicker__day--disabled {
  color: #a0a0a0 !important;
}

.react-datepicker__navigation {
  border: solid var(--color-primary);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  margin-top: 5px;
  margin-right: 10px;
  outline: none;
}

.react-datepicker__navigation--previous {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 10px;
  outline: none;
}

.react-datepicker__navigation--next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  outline: none;
}

.react-datepicker__month {
  margin: 0.5rem;
}

.react-datepicker__current-month {
  font-size: 1.1rem;
  color: var(--grey-font);
  padding-bottom: 1rem;
  font-family: 'NunitoSans-Bold';
}

.react-datepicker__day-name {
  color: var(--grey-font-light-icons);
  text-transform: uppercase;
  width: 2.5rem;
  font-size: 0.8rem;
}

.react-datepicker__day {
  color: var(--grey-font);
  width: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
}

.react-datepicker__day:hover {
  border-radius: 4px;
}

.react-datepicker__day:focus {
  border-radius: 4px;
  outline: none;
}

.react-datepicker__day--selected {
  color: white;
  border-radius: 4px;
  outline: none;
}

.react-datepicker__day--selected:focus {
  outline: none;
}

.react-datepicker__day--keyboard-selected {
  color: black;
  background: white;
  border-radius: 4px;
  outline: none;
}

.react-datepicker__day--keyboard-selected:focus {
  outline: none;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: var(--grey-background);
}

.react-datepicker__day--selected:hover {
  color: white;
  background: rgb(94, 53, 84);
  border-radius: 4px;
  outline: none;
}

.react-datepicker__day.bg-lvl1.react-datepicker__day--selected {
  background-color: var(--color-primary);
}

.react-datepicker__day.bg-lvl2.react-datepicker__day--selected {
  background-color: var(--color-level2);
}

.react-datepicker__day.bg-lvl3.react-datepicker__day--selected {
  background-color: var(--color-level3);
}

.react-datepicker__day.bg-lvl4.react-datepicker__day--selected {
  background-color: var(--color-level4);
}

.header-bg-lvl4 {
  border-color: var(--color-level4);
}

.bookings-date-picker-read-only {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #595959;
}

.booking-date-picker-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px 10px 50px;
  border-radius: 4px;

  &--save {
    color: white;

    &--level-4 {
      background-color: var(--color-level4);
    }

    &--level-3 {
      background-color: var(--color-level3);
    }

    &--level-2 {
      background-color: var(--color-level2);
    }

    &--level-1 {
      background-color: var(--color-primary);
    }
  }
}
