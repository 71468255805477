.category_type {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  margin-bottom: 1em;
  padding: 1em;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(250, 250, 250);

  &_name {
    font-weight: bold;
  }

  &_select {
    padding: 0;
    margin-top: 1em;
  }

  &_select_header {
    padding-left: 0;
  }

  &_row {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;

    &--col {
      flex-direction: column;
    }
  }

  &--active {
    border-width: 1px;
    border-style: solid;
    box-shadow: 0px -2px 20px 0px rgba(94, 53, 84, 0.1), 0px 2px 20px 0px rgba(94, 53, 84, 0.1);
  }

  &_icon {
    margin-right: 2em;
  }

  &_checkbox {
    margin-left: auto;
  }

  &:first-child {
    margin-top: 3em;
  }
}


#active-service {
  border: 1px solid red;
}
