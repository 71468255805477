.account-verification-title {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.account-verification-title-text {
  font-size: 16px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
}

.account-verification-info {
  color: var(--grey-font);
  font-family: 'NunitoSans-Light';
}