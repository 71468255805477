@import '../constants.scss';

.package-card-tile {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.package-card-tile-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.package-card-tile-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(30, 36, 50, 0.2);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  filter: brightness(0.90);
}

.package-card-tile-info {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
}

.package-card-status {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: flex;
  border-radius: 0px 0px 4px 4px;
}

.package-card-status-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 2px;
  filter: var(--filter-dark-grey);
}

.package-card-status-text {
  color: var(--grey-font);
  width: 50%;
}

.package-card-status-opacity {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 4px 4px;
  background-color: white;
  opacity: 0.85;
  z-index: 1;
}

.package-card-tile-info-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  padding: 0px 15px 7px;
}

.package-card-tile-logo-container {
  width: 40%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.package-card-tile-logo {
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.package-card-tile-service {
  color: white;
  font-size: 20px;
  padding-bottom: 5px;
  font-family: "NunitoSans-Bold";
  position: absolute;
  left: 35%;
  bottom: 18%;
}

.package-card-status-use-by {
  font-family: 'NunitoSans-Bold';
  padding-top: 1px;
}

.package-card-tile-status {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 15px;
  margin-top: 15px;
  padding: 0px 12px 0px;
  border-radius: 4px;
  opacity: 0.8;

  &-text {
    font-size: 11px;
    font-family: 'NunitoSans-Bold';
  }
}

@media screen and (max-width: 350px) {
  .package-card-tile {
    height: 130px;
  }

  .package-card-tile-remaining {
    font-size: 16px;
  }

  .package-card-status-opacity {
    font-size: 13px;
  }

  .package-card-tile-service {
    font-size: 16px;
  }

  .package-card-tile-img {
    height: 130px;
  }

  .package-card-tile-info {
    height: calc(130px - 20%);
  }

  .package-card-tile-logo {
    height: 4rem;
    width: 4rem;
  }

  .package-card-tile-status-wrap {
    font-size: 10px;
  }
}
