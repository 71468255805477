.weekDay {
  width: 30px;
  padding: 7px 25px 7px 25px;
  display: flex;
  box-sizing: border-box;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_week {
    color: black;
  }

  &--level-4 {
    .weekDay_number {
      color: var(--color-level4);
    }

    &--disabled {
      .weekDay_number, .weekDay_week {
        color: rgb(160, 160, 160);
      }
    }

    &--active {
      border-style: solid;
      border-width: 1px;
      border-radius: 50%;
      background-color: var(--color-level4);

      .weekDay_week, .weekDay_number {
        color: white;
      }

      &--disabled {
        border-radius: 50%;
        background-color: rgb(160, 160, 160);

        .weekDay_number, .weekDay_week {
          color: white;
        }
      }
    }
  }

  &--level-3 {
    .weekDay_number {
      color: var(--color-level3);
    }

    &--disabled {

      .weekDay_number,
      .weekDay_week {
        color: rgb(160, 160, 160);
      }
    }

    &--active {
      border-style: solid;
      border-width: 1px;
      border-radius: 50%;
      background-color: var(--color-level3);

      .weekDay_week,
      .weekDay_number {
        color: white;
      }

      &--disabled {
        border-radius: 50%;
        background-color: rgb(160, 160, 160);

        .weekDay_number,
        .weekDay_week {
          color: white;
        }
      }
    }
  }

  &--level-2 {
    .weekDay_number {
      color: var(--color-level2);
    }

    &--disabled {

      .weekDay_number,
      .weekDay_week {
        color: rgb(160, 160, 160);
      }
    }

    &--active {
      border-style: solid;
      border-width: 1px;
      border-radius: 50%;
      background-color: var(--color-level2);

      .weekDay_week,
      .weekDay_number {
        color: white;
      }

      &--disabled {
        border-radius: 50%;
        background-color: rgb(160, 160, 160);

        .weekDay_number,
        .weekDay_week {
          color: white;
        }
      }
    }
  }

  &--level-1 {
    .weekDay_number {
      color: var(--color-primary);
    }

    &--disabled {

      .weekDay_number,
      .weekDay_week {
        color: rgb(160, 160, 160);
      }
    }

    &--active {
      border-style: solid;
      border-width: 1px;
      border-radius: 50%;
      background-color: var(--color-primary);

      .weekDay_week,
      .weekDay_number {
        color: white;
      }

      &--disabled {
        border-radius: 50%;
        background-color: rgb(160, 160, 160);

        .weekDay_number,
        .weekDay_week {
          color: white;
        }
      }
    }
  }
}
