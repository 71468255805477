.bg-header {
  height: calc(80px + constant(safe-area-inset-top));
  height: calc(80px + env(safe-area-inset-top));
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-header-top {
  height: calc(80px + constant(safe-area-inset-top));
  height: calc(80px + env(safe-area-inset-top));
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.06;
}

@media screen and (max-width: 350px) {
  .bg-header {
    height: 75px;
  }

  .bg-header-top {
    height: 75px;
  }
}
