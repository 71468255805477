.error-page-container {
  width: 100%;
  height: 100%;
}

.error-page-header {
  display: flex;
  padding: 25px 0px 10px 25px;
}

.error-page-bg-circle-big {
  width: 220px;
  height: 220px;
  border: 20px solid white;
  border-radius: 50%;
  z-index: -1;
  overflow: hidden;
  margin-left: -50px;
  margin-top: -50px;
  opacity: 0.2;
}

.error-page-bg-circle-small {
  width: 40px;
  height: 40px;
  border: 5px solid white;
  border-radius: 50%;
  right: 30%;
  top: 2%;
  z-index: -1;
  overflow: hidden;
  opacity: 0.2;
}

.error-page-go-back-icon {
  width: 1.5rem;
  height: 1.5rem;
  filter: var(--filter-white);
  cursor: pointer;
}

.error-page-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.error-page-title {
  font-family: 'NunitoSans-Bold';
  font-size: 26px;
  color: white;
}

.error-page-problem {
  font-family: 'NunitoSans-Regular';
  font-size: 18px;
  text-align: center;
  color: white;
}

.error-page-customer-info {
  color: white;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  width: 80%;
}

.error-page-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.error-reload-button {
  border-radius: 4px;
  background-color: white;
  border-color: white;
  margin: 1rem auto 0.5rem;
  width: max-content;
  padding: 0.25rem;
}

@media screen and (max-width: 350px) {
  .error-page-bg-circle-big {
    width: 180px;
    height: 180px;
    border: 18px solid white;
  }

  .error-page-bg-circle-small {
    width: 25px;
    height: 25px;
    border: 3px solid white;
  }
}

.error-page-container ~ .Toastify {
  display: none;
}