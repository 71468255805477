.bookingSlider-card-tile-parent {
  display: flex;
  justify-content: center;
  width: 92%;
  height: 75px;
  margin-bottom: 10px;
  color:white;
}

.bookingSlider-details-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 80px;
}

.bookingSlider-details {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  background-color: white;
  width: 100%;
  background-color: white;
}


.bookingSlider-card-tile {
  display: flex;
  width: calc(100% - 5px);
  box-shadow: 0 0 8px 0 rgba(177,188,206,0.45);
  border: 0 solid var(--grey-background);
  height: 75px;
  margin-bottom: 10px;
  color:white;
}


.bookingSlider-card-tile-slider {
  flex-direction: row-reverse;
}

.bookingSlider-card-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  justify-content: space-around;
  font-size: 15px;
  color: var(--grey-font);
}

.bookingSlider-card-image {
  width: 30%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bookingSlider-details-icon {
  filter: var(--filter-grey);
  width: 1.5rem;
  height: 1.5rem;
}

.bookingSlider-status-stripe {
  height: 95%;
  width: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

@media screen and (max-width: 350px) {
  .bookingSlider-card-tile-parent {
    height: 65px;
  }

  .bookingSlider-card-details {
    font-size: 13px;
  }
}

.bookingSlider-slider-parent {
  height:80px;
}

