.pagination-container {
  display: flex;
  width: 90%;
  margin: auto;
  height: 2.2rem;
}

.pagination-button-container {
  width: 2rem;
  margin: auto;
}

.pagination-button {
  display:flex;
  margin:auto;
  border: 1px solid;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}

.pagination-button.selected {
  background-color: var(--color-primary);
  color: white;
}

.pagination-nav-button {
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.center-page-nr {
  margin: auto;
}