.transaction-card-tile {
  display: flex;
  width: 100%;
  height: 95px;
  box-shadow: 0 0 8px 0 rgba(177,188,206,0.45);
  margin-bottom: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.transaction-card-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  justify-content: center;
  font-size: 14px;
  color: var(--grey-font);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.transaction-card-image {
  width: 30%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.transaction-details-icon {
  filter: var(--filter-grey);
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 350px) {
  .transaction-card-tile {
    height: 90px;
  }

  .transaction-card-details {
    font-size: 13px;
  }
}

.transaction-stripe {
  height: 100%;
  width: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}