@import '../constants.scss';

.services {
  scrollbar-width: none;
}

.services-container {
  border-bottom: 0;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  border: 1px solid rgb(221, 221, 221);
}

.services-title {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 2em;
  text-align: center;
  padding-left: 3em;
  display: flex;
  flex-direction: column;
  padding-right: 3em;

  span {
    &:nth-child(2) {
      font-size: small;
    }
  }
}

.service-category {
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
}

.category-name {
  display: flex;
  padding: 6px 5px;
  color: var(--color-primary);
  cursor: pointer;
  border-bottom: 1px solid rgb(221, 221, 221);
  word-break: keep-all;
}

.arrow-icon {
  width: 23px;
  height: 23px;
}

.service {
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
}

.service-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  color: var(--grey-font);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);

  &-with-categories {
    border: 0;
  }
}

.addon-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  color: var(--grey-font);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-container-selected-lvl1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: var(--color-primary);
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-container-selected-lvl2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: var(--color-level2);
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-container-selected-lvl3 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: var(--color-level3);
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-container-selected-lvl4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: var(--color-level4);
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.service-container-locked {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #EFEFEF;
  color: var(--grey-font-light-icons);
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
  pointer-events: none;
}

.addon-container-selected {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: var(--color-primary);
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.addon-container-locked {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  color: var(--grey-font-light-icons);
  padding: 6px 5px 6px 40px;
  border-bottom: 1px solid rgb(221, 221, 221);
  pointer-events: none;
}

.custom-checkbox-container-table {
  display: flex;
  position: relative;
  cursor: pointer;

  input[type=checkbox] {
    display: none;
  }
}

.custom-checkbox-table {
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 0.125rem;
  border: 0.0625rem solid rgb(153, 153, 153);
}

.enable {
  background-color: rgb(255, 255, 255);
}

.disable {
  background-color: var(--grey-font-light-icons);
}

.custom-checkbox-container-table input:checked~.custom-checkbox-table {
  background-color: rgb(255, 255, 255);
}

.custom-checkbox-table:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container-table input:checked~.custom-checkbox-table:after {
  display: block;
}

.custom-checkbox-container-table .custom-checkbox-table:after {
  left: 0.3rem;
  width: 6px;
  height: 10px;
  border: solid red;
  border-width: 0 0.09375rem 0.09375rem 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle-services-chosen {
  // background: var(--color-primary);
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  height: 50px;
  width: 50px;
  border-radius: 50px;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 20px;
  position: absolute;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

.services-chosen-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: calc(102px + constant(safe-area-inset-bottom));
  bottom: calc(102px + env(safe-area-inset-bottom));
  right: 0;
}

.services-chosen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 5px 20px;
  z-index: 1;
  background-color: white;
  box-shadow: 0px -2px 4px 0px rgba(232, 232, 232, 0.59);
  // border: 1px solid rgb(221, 221, 221);
}

.services-chosen-text {
  font-size: 14px;
}

.services-chosen-list {
  padding-top: 10px;
  width: 100%;
}

.service-chosen {
  display: flex;
  background: rgb(250, 250, 250);
  border-radius: 3px;
  border: 0.5px dashed rgb(221, 221, 221);
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  justify-content: space-between;
}

.service-chosen-text {
  color: var(--grey-font);
  font-size: 14px;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-icon {
  border-left: 1px solid #0000001c;
  width: 25px;
  height: 25px;
  padding-left: 5px;
  cursor: pointer;
}

.close-services-chosen-icon {
  width: 30px;
  height: 30px;
  // needs index not to be partially covered by its' own background magically
  z-index: 999999;
}

.search-container {
  position: relative;
  display: flex;
  width: 100%;
  padding: 24px 20px 8px 20px;
  min-height: 48px;
}

@media screen and (max-height: 740px) {
  .category-name {
    display: flex;
    padding: 5px 5px;
    color: var(--color-primary);
    cursor: pointer;
    border-bottom: 1px solid rgb(221, 221, 221);
  }
}

.services-provider-header {
  display: inline-flex;
  justify-content: space-between;
}
