.stamp-card-tile {
  &-container {
    height: auto;
    width: 100%;
  }

  &-body {
    position: relative;
    border: 4px solid;
    border-radius: 4px;
    width: 100%;
    height: 140px;
    box-shadow: 0px 5px 10px 0px rgba(30, 36, 50, 0.2);
  }

  &-image {
    position: absolute;
    width: auto;
    height: 100%;
    z-index: 0;
  }

  &-stamps-container {
    height: 100%;
    width: 50%;
    float: right;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
    padding: 0.5rem 0.5rem 0;
    z-index: 1;
  }

  &-circle {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.27);
    justify-self: end; // squish stamps to the end of the container
    z-index: 2;
  }

  &-circle-stamped {
    width: 80%;
    height: 80%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }

  &-circle-last {
    width: 50%;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }

  &-status {
    position: absolute;
    right: -0.75rem;
    top: -0.75rem;
    width: auto;
    height: 1rem;
    z-index: 1;
    border-radius: 4px;
    text-align: center;
    padding: 0 8px;

    &-text {
      margin: auto 0;
      font-family: "NunitoSans-Bold";
      font-size: 10px;
      color: white;
    }
  }

  &-condition {
    height: auto;
    max-height: 30%;
    width: 100%;
    padding: 0.5rem 0;

    &-text {
      display: inline-block;
      font-family: "NunitoSans-Bold";
      font-size: 14px;
      font-weight: bold;
    }
  }

  &-use-by {
    height: auto;
    max-height: 10%;
    width: 100%;

    &-text {
      font-family: "NunitoSans-Regular";
      font-size: 12px;
      color: var(--grey-font);
    }
  }
}

@media screen and(max-width: 325px) {
  .stamp-card-tile {
    &-circle {
      height: 40px;
      width: 40px;
    }
  }
}
