.search-icon {
  width: 35px;
  height: 35px;
  padding-top: 4px;
}

.location-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 18px 0px;
  border-bottom: 1px solid var(--grey-dividers);
  align-items: center;
  cursor: pointer;
}

.location-container:last-child {
  border-bottom: 0px;
}

.locations-not-found {
  text-align: center;
  font-style: italic;
}

.location-name {
  color: var(--grey-font);
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 28px;
}

.locations-title {
  text-align: center;
  font-weight: bold;
}

@media screen and (max-height: 740px) {
  .location-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px 0px;
    border-bottom: 1px solid var(--grey-dividers);
    align-items: center;
    cursor: pointer;
  }

  .location-container:last-child {
    border-bottom: 0px;
  }

  .location-name {
    color: var(--grey-font);
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
  }
}
