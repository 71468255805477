.dashboard-body-container {
  display: flex;
  text-align: left;
  align-items: center;
  flex-direction: column;
  margin-top: -150px;
  --header-card-width: 8%;
}

.dashboard-container {
  position: relative;
  width: 100%;
  text-align: -moz-center;
  text-align: -webkit-center;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: var(--grey-background);
}

.dashboard-card-container {
  display: flex;
  width: calc(100% - var(--header-card-width));
}

.dashboard-current-level {
  filter: var(--filter-white);
  width: 45%;
  padding-right: 10px;
}

.dashboard-current-level-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
  padding: 20px 0px;
}

.dashboard-text {
  color: white;
  font-size: 16px;
}

.dashboard-learn-more {
  color: white ;
  font-size: 14px;
}

.dashboard-learn-more-icon {
  filter: var(--filter-white);
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  margin-bottom: 3px;
  cursor: pointer;
}

.primary-small-cards-container {
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.vouchers {
  display: flex;
  flex-direction: column;
  min-height: max-content;
  margin-bottom: 40px;
}

.booking-info {
  display: flex;
  width: 70%;
  flex-direction: column;
}

.booking-card {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.booking-date {
  display: flex;
  width: 65%;
  align-items: center;
}

.booking-hour {
  display: flex;
  align-items: center;
}

.booking-date-text {
  color: var(--grey-font);
  font-family: 'NunitoSans-SemiBold';
  font-size: 16px;
}

.booking-icon {
  background-color: var(--grey-background);
  margin-right: 10px;
}

.booking-icon-img {
  width: 4rem;
  height: 4rem;
  filter: var(--filter-grey);
}

.calendar-icon {
  width: 2rem;
  height: 2rem;
  margin-left: -7px;
  filter: var(--filter-grey);
}

.clock-icon {
  width: 2rem;
  height: 2rem;
  filter: var(--filter-grey);
}

.booking-hour-text {
  color: var(--grey-font);
  font-family: 'NunitoSans-SemiBold';
  font-size: 16px;
}

.booking-location {
  color: var(--grey-font);
  font-size: 14px;
}

.dashboard-upcoming-bookings {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: max-content;
  margin-bottom: 40px;
}

.section-cards-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  z-index: 1;
  overflow-x: visible;
}

.section-badge-expiration {
  color: white;
  font-family: 'NunitoSans-Bold';
  font-size: 10px;
  font-weight: bold;
  height: 14px;
  letter-spacing: 0px;
  width: 125px;
  background: #5e3554;
  border-radius: 8px;
  text-align: center;
  opacity: 0.6;
}

.section-referral-image-container {
  position: absolute;
  width: 45%;
  height: inherit;
  bottom: 0;
  right: -7%;
}

.section-img {
  position: absolute;
  bottom: 0;
  height: inherit;
  width: 90%;
}

.footer-spacing{
  height: 50px;
  width: 100%;
}

.section-referral-content {
  width: 55%;
  height: 100%;
  z-index: 2;
}

.section-content-text {
  font-size: 11px;
}

.section-content-title {
  font-size: 13px;
  font-weight: 800;
  font-family: 'NunitoSans-ExtraBold';
}

.section-cards-container-referral {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.section-referral {
  position: relative;
  display: flex;
  height: auto;
  width: 100%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.section-referral-content-title {
  width: 100%;
  color:  white;
  font-size: 15px;
  font-family: "NunitoSans-ExtraBold";
  font-weight: 800;
  letter-spacing: 0.34px;
}

.section-referral-content-text {
  color:  white;
  font-family: "NunitoSans-Regular";
  font-size: 12px;
}

.dashboard-bg-content-top {
  height: 210px;
  width: 100%;
  position: relative;
}

.dashboard-bg-opacity {
  position: absolute;
  top: 0;
  height: 210px;
  width: 100%;
  background-color: white;
  opacity: 0.06;
}

.title-slide-treat {
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  height: 24px;
}

@media screen and (max-width: 350px) {
  .primary-small-cards-container {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .dashboard-current-level {
    filter: var(--filter-white);
    width: 45%;
    padding-right: 5px;
  }

  .dashboard-learn-more {
    color: white ;
    font-size: 14px;
  }

  .dashboard-current-level-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    padding: 20px 0px;
  }

  .booking-icon-img {
    width: 3.7rem;
    height: 3.7rem;
    filter: var(--filter-grey);
  }

  .booking-date-text {
    color: var(--grey-font);
    font-family: 'NunitoSans-SemiBold';
    font-size: 14px;
  }

  .calendar-icon {
    width: 1.7rem;
    height: 1.7rem;
    margin-left: -7px;
    filter: var(--filter-grey);
  }

  .clock-icon {
    width: 1.7rem;
    height: 1.7rem;
    filter: var(--filter-grey);
  }

  .booking-hour-text {
    color: var(--grey-font);
    font-family: 'NunitoSans-SemiBold';
    font-size: 14px;
  }

  .booking-location {
    font-size: 13px;
  }

  .dashboard-upcoming-bookings {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: max-content;
    margin-bottom: 40px;
  }

  .vouchers {
    display: flex;
    flex-direction: column;
    min-height: max-content;
    margin-bottom: 20px;
  }

  .section-referral-content-title {
    font-size: 13px;
  }

  .section-referral-content-text {
    font-size: 10px;
  }
}

@media screen and (min-width: 410px) {
  .section-referral-content-text {
    font-size: 14px;
  }
}
