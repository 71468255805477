.invite-friend-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.invite-friend-top {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url('../../img/invite-a-friend-bg.png');
  background-size: cover;
}

.invite-friend-top-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  padding: 30px 0 10px 0;
}

.invite-friend-top-back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.invite-friend-top-text {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-family: 'NunitoSans-Bold';
  color: white;
  padding: 2px 10px;
  border-radius: 4px;
  opacity: 0.9;
}

.invite-friend-location-logo {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  z-index: 1;
  top: -2.25rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-style: solid;
  border-width: thick;
  border-radius: 50%;
}

.invite-friend-bottom {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 60%;
}

.invite-friend-bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invite-friend-bottom-title {
  font-size: 22px;
  font-family: "NunitoSans-Bold";
  color: white;
}

.invite-friend-bottom-text {
  font-size: 16px;
  font-family: "NunitoSans-Regular";
  color: white;
}

.invite-friend-bottom-link {
  font-size: 14px;
  font-family: "NunitoSans-Regular";
  color: white;
  text-decoration: underline;
}

.invite-friend-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  color: white;
  font-size: 20px;
  font-family: "NunitoSans-Bold";
  border-radius: 4px;
  padding: 4px 16px;
  margin-bottom: 16px;

  .invite-friend-button-icon {
    width: 1rem;
    height: 1rem;
    fill: white;
    margin-right: 8px;
  }

  &.disabled {
    cursor: none;
    background-color: rgb(177, 188, 206);
    color: var(--color-primary);
    .invite-friend-button-icon {
      fill: var(--color-primary);
     }
  }
}



.invite-friend-share-container {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 60%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  z-index: 2;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.44);
}

.invite-friend-share-title {
  font-size: 12px;
  font-family: "NunitoSans-Bold";
  color: white;
}

.invite-friend-share-link {
  color: white;
  font-family: 'NunitoSans-LightItalic';
  font-size: 16px;
  font-weight: 300;
  text-decoration: underline;
}

.invite-friend-share-title-sep {
  width: 100%;
  border-color: white;
  border-width: 0.25px;
}

.invite-friend-share-choices {
  position: relative;
  width: 100%;
  height: auto;
  overflow: scroll;
  scrollbar-width: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.invite-friend-share-choices-item {
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.invite-friend-share-choices-item-icon {
  width: 3.5rem;
  height: 3.5rem;
}

.invite-friend-share-choices-item-text {
  color: white;
  font-family: 'NunitoSans-SemiBold';
  font-size: 12px;
}

@media screen and (max-width: 325px) {
  .invite-friend-bottom-title {
    font-size: 20px;
  }

  .invite-friend-bottom-text {
    font-size: 14px;
  }

  .invite-friend-bottom-link {
    font-size: 12px;
  }
}

@media screen and (min-width: 370px) {
  .invite-friend-location-logo {
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: -2.5rem;
  }
}

@media screen and (min-width: 410px) {
  .invite-friend-location-logo {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    top: -2.75rem;
  }
}