.dashboard-wallet-container {
  position: relative;
  margin-bottom: 70px;
  overflow: scroll;
  scrollbar-width: none;
  --header-card-width: 0%;
}

.dashboard-wallet-bg-content-top {
  height: 200px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.dashboard-wallet-bg-content-top-opacity {
  position: absolute;
  top: 0;
  height: 200px;
  width: 100%;
  background-color: white;
  opacity: 0.06;
}

.dashboard-wallet-img-container {
  display: flex;
  width: 100%;
  padding: 10px 15px;
  margin-top: -200px;
}

.dashboard-wallet-img {
  z-index: 1;
  background-image: url('../../../assets/img/hairdresser_wallet.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: var(--color-grey-image);
  border-radius: 4px;
}

/**
 * IMAGE CONTENT
 */

.dashboard-wallet-img-container .primary-card {
  padding: 10px;
}

.dashboard-wallet-img-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dashboard-wallet-img-content-title {
  font-size: 16px;
  font-family: 'NunitoSans-SemiBold';
  color: white;
}

.dashboard-wallet-img-content-opacity-text {
  font-size: 14px;
  font-family: 'NunitoSans-Bold';
  color: #FFFFFF80; // opacity 50%
}

.dashboard-wallet-img-content-caption {
  font-size: 12px;
  font-family: 'NunitoSans-SemiBold';
  color: white;
}

.dashboard-wallet-img-content-opacity-caption {
  font-size: 12px;
  font-family: 'NunitoSans-SemiBold';
  color: #FFFFFF80; // opacity 50%
}

.dashboard-wallet-right-icon {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  filter: var(--filter-white);
  opacity: 0.5;
  margin-top: 3px;
}

.dashboard-wallet-section-container {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 350px) {
  .dashboard-wallet-bg-content-top {
    height: 180px;
  }

  .dashboard-wallet-bg-content-top-opacity {
    height: 180px;
  }

  .dashboard-wallet-img-container {
    padding: 10px 15px 0px 15px;
    margin-top: -180px;
  }

  .dashboard-wallet-right-icon {
    width: 0.8rem;
    height: 0.8rem;
  }

  .dashboard-wallet-img-content-title {
    font-size: 14px;
  }

  .dashboard-wallet-img-content-opacity-text {
    font-size: 12px;
  }
}
