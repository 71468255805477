.icon-settings-profile-header {
  width: 8rem;
}

.profile-activation-date {
  font-family: 'NunitoSans-Regular';
  font-size: 13px;
  font-weight: normal;
  height: 19px;
  letter-spacing: 1px;
  width: 177px;
}

.profile-form-container {
  padding-top: 90px;
}

.label-profile-form {
  text-align: left;
  font-family: 'NunitoSans-Regular';
  font-size: 15px;
  font-weight: normal;
  height: 22px;
  letter-spacing: 0px;
}

.tab {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100%;
  scrollbar-width: none;
}

.input-profile {
  background: rgb(250, 250, 250) !important;
  border-radius: 3px !important;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.right-label-input {
  position: absolute;
  top: -13px;
  right: 3px;
  display: flex;
  align-content: center;
  align-items: center;
}

.icon-right-settings {
  width: 1.5rem;
}

.footer-spacing-profile {
  height: 65px;
  width: 100%;
}

.dateOfBirth {
  -webkit-text-fill-color: black;
  color: black;
  opacity: 1;
}

.profile-deletion-icon-width {
  width: 8rem;
}