.radio-input {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &-label {
    color: #69696A;

    &--checked {
      &--level-1 {
        color: var(--color-primary);
      }

      &--level-2 {
        color: var(--color-level2);
      }

      &--level-3 {
        color: var(--color-level3);
      }

      &--level-4 {
        color: var(--color-level4);
      }
    }
  }

  &-circle {
    width: 0px; // hide native input
    -webkit-appearance: none; // hide input on safari/firefox browsers
    position: relative;
    height: 2rem;

    &::before {
      border-radius: 50%;
      content: "";
      display: grid;
      height: 2rem;
      width: 2rem;
      border: 3px solid #C2C3C3;
      top: 0;
      position: absolute;
    }

    &:after {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
      content: "";
      place-content: center;
      box-sizing: border-box;
      display: block;
      border-radius: 50%;
    }

    &-level1 {
      &:checked::before {
        border: 3px solid var(--color-primary);
      }

      &:checked::after {
        background-color: var(--color-primary);
      }
    }

    &-level2 {
      &:checked::before {
        border: 3px solid var(--color-level2);
      }

      &:checked::after {
        background-color: var(--color-level2);
      }
    }

    &-level3 {
      &:checked::before {
        border: 3px solid var(--color-level3);
      }

      &:checked::after {
        background-color: var(--color-level3);
      }
    }

    &-level4 {
      &:checked::before {
        border: 3px solid var(--color-level4);
      }

      &:checked::after {
        background-color: var(--color-level4);
      }
    }
  }
}
