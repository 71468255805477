.registration-page {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.registration-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  background-image: url('../../img/sign_in_background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 101%;
  z-index: -1;
}

.registration-title {
  font-size: 16px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
  padding-bottom: 15px;
}

.registration-text {
  color: var(--grey-font-grey);
  font-family: 'NunitoSans-Light';
  padding-bottom: 30px;
}

.registration-cellphone {
  background: var(--grey-background) !important;
  border-radius: 3px !important;
  width: 100%;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px 7px 7px 45px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.registration-cellphone-error {
  background: var(--grey-background) !important;
  border-radius: 3px !important;
  width: 100%;
  border: 1px solid red !important;
  padding: 7px 7px 7px 45px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.registration-tab-separator {
  border-top: 1px solid #dddddd;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.registration-text-primary {
  color: var(--color-primary);
  font-family: "NunitoSans-Regular";
  font-size: 14px;
}

.registration-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registration-registration {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 20px;
}

.registration-select {
  -webkit-appearance: none;
}

.registration-location-option {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
}

.registration-input {
  background: rgb(250, 250, 250) !important;
  border-radius: 4px !important;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
  &:disabled {
    border: 1px solid #e0e0e1; 
    color: #939597; 
    background-color: #e9e8e8 !important; 
    transition: background-color 0.3s, color 0.3s;
    cursor: not-allowed; 
  }
}

.registration-input-error {
  background: rgb(250, 250, 250) !important;
  border-radius: 4px !important;
  border: 1px solid red !important;
  padding: 7px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}
.verification-code-input{
  padding: 8px 10px;
}