@import '../constants.scss';

.popup-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.popup-modal .swiper-slide {
  align-self: auto;
}

.shared-popup-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 4px;
  min-height: 420px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.popup-modal-container {
  position: relative;
  width: 85%;
  height: 70%;
  scrollbar-width: none;
  border-radius: 4px;
  transition: opacity .3s ease;
  margin: 0 auto;
  overflow-y: scroll;
}

.popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}

.popup-body {
  display: flex;
  flex-direction: column;
  text-align: center;
  white-space: pre-line;
}

.popup-close-icon {
  width: 2rem;
  height: 2rem;
  filter: var(--filter-white);
}

.popup-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 1rem 0;
  margin-top: auto;
}

.popup-action-button {
  display: flex;
  width: 100%;
  font-size: 22px;
  font-family: 'NunitoSans-Bold';
  justify-content: center;
  align-items: center;
  background-color: white;
  border: .0625rem solid white;
  transition: .2s;
  margin: 10px;
  height: 50px;
  border-radius: 4px;
}

.popup-back-button {
  display: flex;
  justify-content: center;
  color: white;
  transition: .2s;
  padding: 10px;
  font-family: 'NunitoSans-Bold';
  text-decoration: underline;
  font-size: 18px;
  text-underline-offset: 3px;
}

.popup-modal-icon {
  width: 4rem;
  filter: var(--filter-white);
  padding: 3rem 0;
}

.popup-modal-component-icon {
  width: 4rem;
  padding: 3rem 0;
  margin: auto;
}

.popup-title {
  text-align: center;
  font-size: 20px;
  color: white;
  font-family: 'NunitoSans-Bold';
}

.popup-text {
  font-size: 16px;
  color: white;
  font-family: 'NunitoSans-Regular';
  padding: 0 20px 0 20px;
}

/**
 * OVERRIDE SURVEY MODAL DETAILS
 */

.popup-modal-container .stars-container {
  width: 60%;
}

.popup-modal-container .request {
  width: 100%;
}

.popup-modal-container .survey-details-button {
  width: 100%;
  font-family: 'NunitoSans-SemiBold';
  color: white;
  border-radius: 4px;
  margin-bottom: 20px;
}

/**
 * OVERRIDES FOR SURVEY MODAL DETAILS
 */

.popup-modal .Toastify__toast-container {
  width: 100%;
  position: absolute;
}

.popup-modal-container .stars-container {
  width: 60%;
}

.popup-modal-container .request {
  width: 100%;
}

.popup-modal-container .survey-details-button {
  width: 100%;
  font-family: 'NunitoSans-SemiBold';
  color: white;
  border-radius: 4px;
  margin-bottom: 20px;
}

/**
 * CUSTOM CLASSES
 */

.popup-level-upgrade-icon {
  width: 8rem;
  filter: var(--filter-white);
}

.popup-birthday-icon {
  width: 18rem;
  filter: var(--filter-white);
  margin-top: 2rem;
}

.popup-birthday-title {
  font-size: 16px;
  color: white;
  font-family: 'NunitoSans-SemiBold';
}

.popup-stamp-earned-icon {
  height: 120px;
  width: 140px;
  filter: var(--filter-white);
  transform-origin: center;
  transform: rotate(-20deg);
}

.popup-stamp-earned-title {
  margin-bottom: 1rem;
}

.popup-stamp-new {
  width: 80%;
  margin-top: 4rem;

  .stamp-card-tile-body {
    height: 120px;
  }

  .stamp-card-tile-stamps-container {
    padding: 0.75rem 0.75rem 0;
  }

  .stamp-card-tile-circle {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 350px) {

  .popup-stamp-earned-icon {
    width: 100px;
  }

  .shared-popup-modal {
    min-height: 400px;
  }

  .popup-action-button {
    font-size: 20px;
  }

  .popup-button-back {
    font-size: 16px;
  }

  .popup-title {
    font-size: 18px;
    margin: 0;
  }

  .popup-level-upgrade-icon {
    width: 7rem;
  }

  .popup-birthday-icon {
    width: 15rem;
  }

  .popup-birthday-title {
    font-size: 18px;
  }

  .popup-text {
    font-size: 14px;
  }

  .popup-action-button {
    height: 40px;
  }
}

@media screen and (min-width: 351px) and (max-width: 399px) {
  .popup-stamp-earned-icon {
    width: 120px;
  }

  .shared-popup-modal {
    min-height: 400px;
  }

  .popup-action-button {
    font-size: 20px;
  }

  .popup-button-back {
    font-size: 16px;
  }

  .popup-title {
    font-size: 21px;
    margin: 0;
  }

  .popup-level-upgrade-icon {
    width: 10rem;
  }

  .popup-birthday-icon {
    width: 15rem;
  }

  .popup-birthday-title {
    font-size: 21px;
  }

  .popup-text {
    font-size: 17px;
  }

  .popup-action-button {
    height: 40px;
  }
}

@media screen and (min-width: 401px) and (max-width: 450px) {
  .popup-stamp-earned-icon {
    width: 130px;
    height: 35% !important;
  }

  .popup-stamp-new {
    height: 35% !important;
  }

  .shared-popup-modal {
    height: 100%;
  }

  .popup-action-button {
    font-size: 20px;
  }

  .popup-button-back {
    font-size: 16px;
  }

  .popup-title {
    font-size: 21px;
    margin: 0;
  }

  .popup-level-upgrade-icon {
    width: 10rem;
    height: 45%;
  }

  .popup-birthday-icon {
    width: 20rem;
  }

  .popup-birthday-title {
    font-size: 21px;
  }

  .popup-text {
    font-size: 19px;
  }

  .popup-action-button {
    height: 40px;
  }
}

/**
 * OVERRIDES SWIPPER ON POPUPS ONLY
 */

.popup-modal-container .swiper-slide-active {
  height: 100% !important;
}

.popup-modal-container .swiper {
  height: 100%;
}
