.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.loading-icon {
  width: 6rem;
  height: 6rem;
}

.loading-text {
  color: white;
  font-family: 'NunitoSans-SemiBold';
  font-size: 18px;
}

@media screen and (max-width: 350px) {
  .loading-icon {
    width: 5rem;
    height: 5rem;
  }

  .loading-text {
    font-size: 16px;
  }
}