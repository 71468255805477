@import './constants.scss';

.sign-in-page-container {
  height: 100%;
  // width: 100%;
  // overflow: scroll;
  overflow-y: scroll;
  scrollbar-width: none;
  background-position: center;
  background-repeat: no-repeat;
  // object-fit: fill;
  width: 100.3vw;
  background-size: cover;
  background-image: url('../img/sign_in_background.png');
}
.payment-callback-handler-container{
  background-image: url('../img/sign_in_background.png');
}
.sign-in-page-footer {
  color: white;
  font-size: 0.8em;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.sign-in-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.reset-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 25px;
}

.activation-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
}

.activation-bg-container {
  position: absolute;
  width: 101%;
  height: 101%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.activation-bg {
  width: 100%;
  height: 100%;
}

.reset-title {
  color: var(--color-primary);
  font-size: 18px;
  font-family: 'NunitoSans-Bold';
  padding-bottom: 15px;
}

.sign-tabs {
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
  padding: 20px 0px 45px 0px;
}

.email-text {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
}

.password-text {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
}

.phone-number-text {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
}

.phone-number-text {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
}

.login-tab-title {
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px;
  border-bottom: 1px solid white;
}

.activation-tab-title {
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px;
  border-bottom: 1px solid white;
}

.login-tab-title-selected {
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px;
  border-bottom: 3px solid white;
}

.activation-tab-title-selected {
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px;
  border-bottom: 3px solid white;
}

.label {
  font-size: 15px;
}

.tab-container {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 25px 20px;
}

.forgot-password-title {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}


.forgot-password-text {
  font-size: 18px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
}

.activation-text {
  font-size: 16px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
  padding-bottom: 15px;
}

.activation-title {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.forgot-password-title {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}


.forgot-password-text {
  font-size: 16px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
}

.ovf-none {
  overflow: visible !important;
}

.tab {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100%;
  scrollbar-width: none;
}

.back-icon-forgot-password {
  width: 1rem;
  margin-right: 8px;
}

.no-account-sign-up {
  margin-top: 40px;
  color: white;
  font-size: 14px;
}

.tab-text {
  color: var(--grey-font-grey);
  font-family: 'NunitoSans-Light';
  padding-bottom: 30px;
}

.forgot-password-subtitle {
  color: var(--grey-font-grey);
  font-family: 'NunitoSans-Light';
  padding-bottom: 30px;
}

.activation-subtitle {
  color: var(--grey-font-grey);
  font-family: 'NunitoSans-Light';
  padding-bottom: 30px;
}

.forgot-password-email-address {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
  font-size: 15px;
}

.tab-separator-sign-in {
  border-top: 1px solid rgb(221, 221, 221);
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.text-primary-activation {
  color: var(--color-primary);
  font-family: 'NunitoSans-Regular';
  font-size: 14px;
}

.separator-text {
  color: var(--grey-dividers);
  font-size: 16px;
  padding: 10px 20px;
}

.use-email-phone-text {
  color: var(--color-primary);
  font-family: 'NunitoSans-SemiBold';
  font-size: 16px;
}

.use-email-phone-text-underline {
  color: var(--color-primary);
  font-size: 16px;
}

.use-email-phone-text-underline {
  color: var(--color-primary);
  font-size: 16px;
  text-decoration: underline;
}

.email-icon {
  position: absolute;
  top: 11px;
  right: 10px;
}

.pass-icon {
  position: absolute;
  top: 11px;
  right: 10px;
}

.phone-icon {
  position: absolute;
  top: 9px;
  right: 10px;
}

.logo-tns-sign-in {
  width: 5rem;
  height: 5rem;
  filter: var(--filter-white);
}

.logo-marquee-sign-in {
  width: 5rem;
  height: 5rem;
  filter: var(--filter-white);
}

.use-email-phone-icon {
  filter: var(--filter-primary);
  padding-right: 6px;
  width: 1.4rem;
}

.input-signin {
  background: rgb(250, 250, 250) !important;
  border-radius: 4px !important;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.intl-tel-input {
  width: 100%;
}

.intl-tel-input .flag-container {
  left: 0;
}

.input-signin-cell-phone {
  background: var(--grey-background) !important;
  border-radius: 3px !important;
  width: 100%;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px 7px 7px 45px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.input-signin-cell-phone:hover {
  background: rgb(243, 242, 242) !important;
  border-radius: 3px !important;
  width: 100%;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px 7px 7px 45px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.input-signin-cell-phone-error {
  background: rgb(250, 250, 250) !important;
  border-radius: 3px !important;
  width: 100%;
  border: 1px solid red !important;
  padding: 7px 7px 7px 45px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.input-signin-cell-phone-error:hover {
  background: rgb(242, 242, 242) !important;
  border-radius: 3px !important;
  width: 100%;
  border: 1px solid red !important;
  padding: 7px 7px 7px 45px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.input-signin:hover {
  background: rgb(243, 242, 242) !important;
  border-radius: 3px !important;
  border: 1px solid var(--grey-dividers) !important;
  padding: 7px !important;
  outline: none !important;
  width: 100%;
}

.input-signin-error {
  border: 1px solid red !important;
  border-radius: 3px !important;
  background: rgb(250, 250, 250) !important;
  padding: 7px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.input-signin-error:hover {
  border: 1px solid red !important;
  border-radius: 3px !important;
  background: rgb(243, 242, 242) !important;
  padding: 7px !important;
  outline: none !important;
  transition: 0.5s;
  width: 100%;
}

.signin-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
}

.forgot-password {
  color: var(--color-primary);
  font-family: 'NunitoSans-Italic';
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
}

.forgot-password-click-here {
  color: var(--color-primary);
  font-family: 'NunitoSans-Italic';
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.container-sign-in {
  flex: 1;
  min-height: 0px;
  margin-bottom: 80px;
}

input:invalid {
  border: 1px solid red !important;
}

@media screen and (max-height: 735px) {
  .reset-title {
    font-size: 16px;
  }

  .forgot-password-text {
    font-size: 16px;
  }

  .label {
    font-size: 14px;
  }

  .tab {
    font-size: 13px;
  }

  .forgot-password {
    font-size: 13px;
  }

  .forgot-password-click-here {
    font-size: 11px;
  }

  .login-tab-title {
    font-size: 14px;
  }

  .activation-tab-title {
    font-size: 14px;
  }

  .login-tab-title-selected {
    font-size: 14px;
  }

  .activation-tab-title-selected {
    font-size: 14px;
  }

  .separator-text {
    color: var(--grey-dividers);
    font-size: 14px;
    padding: 10px 20px;
  }

  .use-email-phone-text {
    font-size: 14px;
  }

  .use-email-phone-text-underline {
    font-size: 14px;
  }
}