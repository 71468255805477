.filters-box {
  z-index: 22;
  width: 100%;
  background-color: white;
  color: black;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  max-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0px;

  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.4;
    background: black;
    z-index: 21;
  }

  &--open {
    max-height: 80%;
    padding-bottom: 40px;
  }

  &-button {
    padding: 15px 20px 15px 20px;
    color: white;
    border-radius: 0.5rem;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;

    &--level-1 {
      background-color: var(--color-primary);
    }

    &--level-2 {
      background-color: var(--color-level2);
    }

    &--level-3 {
      background-color: var(--color-level3);
    }

    &--level-4 {
      background-color: var(--color-level4);
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .filters-box {
    &-button {
      width: 50%;
    }
  }
}
