.message-card {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border-radius: 4px;
}

.message-title {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

.message-date {
  color: var(--grey-font);
  font-family: 'NunitoSans-Bold';
  font-size: 16px;
}

.message-text {
  color: var(--grey-font);
  font-size: 15px;
}

.message-icon {
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
}

.no-messages {
  margin-top: 25px;
  display: flex;
  align-self: center;
  color: var(--grey-font);
  font-size: 16px;
}

@media screen and (max-width: 350px) {
  .message-date {
    color: var(--grey-font);
    font-family: 'NunitoSans-Bold';
    font-size: 14px;
  }

  .message-text {
    color: var(--grey-font);
    font-size: 14px;
  }

  .message-title {
    color: var(--color-primary);
    font-family: 'NunitoSans-Bold';
    font-size: 14px;
  }

  .no-messages {
    margin-top: 25px;
    display: flex;
    align-self: center;
    color: var(--grey-font);
    font-size: 14px;
  }
}

.messages-profile-container {
  text-align: left;
  background-color: var(--grey-background);
  flex-direction: column;
  padding: 0 12px;
}

.message-new-green-oval{
  background: #a0cf93;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-left: 3px;
  margin-top: 7px;
}


.message-grey-divider {
  border-bottom: 2px solid var(--grey-dividers);
}