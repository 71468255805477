.box-title {
  border-radius: 3px 3px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'NunitoSans-Regular';
  min-height: 58px;
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.request-container {
  background: var(--grey-background);
  border: 1px solid #b1bcce;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 15rem;
  padding: 15px;
  flex: 1;
}

.request-title {
  color: var(--grey-font-light-icons);
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
}

.request {
  resize: none;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid var(--grey-dividers);
  min-height: 9rem;
  padding: 10px;
  flex: 1;
}

.request::placeholder {
  color: rgb(204, 204, 204);
}
