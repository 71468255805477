@import '../constants.scss';

.box-title {
  border-radius: 3px 3px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'NunitoSans-Regular';
  padding: 15px 0px;
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.booking-confirmed {
  background-color: var(--color-green);
  color: white;
}

.confirm-booking {
  background-color: var(--color-yellow);
  color: white;
}

.container {
  background: var(--grey-background);
  border: 1px solid #b1bcce;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.data-title {
  min-height: 25px;
  display: flex;
}

.category-container {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.category-container-with-border-bottom {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
}

.category-title {
  width: 100%;
  display: flex;
  min-height: 40px;
  color: var(--grey-font);
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
}

.arrow-icon-category {
  width: 20px;
  height: 20px;
}

.icon {
  width: 27px;
  height: 27px;
}

.placeholder {
  font-size: 18px;
  font-family: 'NunitoSans-SemiBold';
}

.placeholder-time {
  font-family: 'NunitoSans-SemiBold';
  color: var(--grey-font);
  font-size: 16px;
}

.data-text {
  font-size: 15px;
  min-height: 16px;
  color: var(--grey-font);
}

.data-text-primary {
  font-size: 15px;
  min-height: 16px;
}

.data-text-provider {
  font-size: 15px;
  min-height: 16px;
  color: var(--grey-font);
}

.provider-tenure-text {
  color: var(--color-grey);
  font-size: 12px;
}

.alert-icon {
  padding-right: 10px;
  width: 2.2rem;
  filter: var(--filter-white);
}

.rating-icon {
  padding-right: 10px;
  width: 2.8rem;
  filter: var(--filter-white);
  margin-bottom: 10px;
  margin-top: 3px;
}

.tenure {
  color: var(--color-grey)
}

@media screen and (max-width: 350px) {
  .data-text-primary {
    font-size: 13px;
    min-height: 16px;
    color: var(--color-primary);
  }

  .data-text {
    font-size: 13px;
    min-height: 16px;
  }

  .data-text-provider {
    font-size: 13px;
    min-height: 16px;
    color: var(--grey-font);
  }
}

@media screen and (max-height: 740px) {
  .subtitle {
    flex: 0 0 50px !important;
  }
}
