@import '../constants.scss';

.loyalty-learn-more-content-container {
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 70px;
  z-index: 0;
}

.loyalty-learn-more-content-container p {
  margin-bottom: 16px;
}

.loyalty-learn-more-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  min-height: 250px;
  width: 100%;
}

.loyalty-learn-more-header-title {
  font-size: 16px;
  font-family: 'NunitoSans-ExtraBold';
  color: white;
}

.loyalty-learn-more-header-text {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
  color: white;
}

.loyalty-learn-more-header-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding-top: 12px;
}

.loyalty-learn-more-header-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.loyalty-learn-more-header-content-text {
  padding: 16px 16px 0 16px;
  text-align: center;
}

.loyalty-learn-more-header-how {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loyalty-learn-more-header-how-text {
  font-size: 16px;
  font-family: 'NunitoSans-Bold';
  text-decoration: underline;
  color: white;
}

.loyalty-learn-more-header-icon {
  width: 1.5rem;
  height: 1.5rem;
  // filter: var(--filter-white);
  transform: rotate(-90deg);
}

.loyalty-learn-more-header-gradient {
  position: absolute;
  height: 100%;
  width: 100%;
}

.loyalty-learn-more-header-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.loyalty-learn-more-content-title {
  font-size: 20px;
  font-family: 'NunitoSans-Bold';
}

.loyalty-learn-more-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
  border-radius: 4px;
}

.loyalty-learn-more-card-logo {
  padding: 0 10px 0 10px;
  margin: 10px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: var(--filter-white);
}

.level-cashback {
  font-size: 18px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
}

.loyalty-learn-more-card-text {
  font-size: 12px;
  font-family: 'NunitoSans-Regular';
  color: white;
}

.loyalty-learn-more-content-level {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 20px 23px;
  margin-bottom: 1rem;
}

.loyalty-learn-more-content-level-title {
  font-size: 18px;
  font-family: 'NunitoSans-ExtraBold';
  padding: 10px 10px 20px 10px;
}

.loyalty-learn-more-content-text {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
  color: var(--grey-font);
}

.loyalty-learn-more-content-terms {
  font-size: 14px;
  font-family: 'NunitoSans-Bold';
  text-decoration: underline;
}

.loyalty-learn-more-terms-conditions {
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: scroll;
  scrollbar-width: none;
  width: 100%;
  height: auto;
  max-height: 80%;
  padding: 30px 20px;
  margin-bottom: 20px;
  text-align: left;
  background-color: white;
  box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.09);
  z-index: 1;
}

.loyalty-learn-more-terms-conditions p {
  margin-bottom: 16px;
}


.loyalty-learn-more-terms-conditions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.loyalty-learn-more-content-text p {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
  color: var(--grey-font);
  line-height: 25px;
}

.loyalty-learn-more-terms-conditions-title {
  font-size: 16px;
  font-family: 'NunitoSans-Bold';
  width: 90%;
}

.loyalty-learn-more-terms-conditions-close-icon {
  width: 1.2rem;
  height: 1.2rem;
}

@media screen and (max-width: 350px) {
  .loyalty-learn-more-content-title {
    font-size: 18px;
  }
}