@import "../constants.scss";

.level {
  width: 100%;
  border-radius: 3px;
  display: flex;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  max-height: 200px;
  margin-bottom: 20px;
}

.level-no-filter {
  width: 40%;
}

.level-information {
  width: 55%;
  display: flex;
  height: 100%;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.level-text {
  display: flex;
  flex-direction: column;
}

.level-text-awesome {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: 'NunitoSans-Black';
  text-transform: uppercase;
}

.level-text-member {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
}

.level-learn-more {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.level-learn-more-icon {
  width: 1.5rem;
  filter: var(--filter-white);
}

.level-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 200px;
  margin-bottom: 20px;
}

.level-details-box {
  border-radius: 3px;
  display: flex;
  max-height: 100px;
  align-items: flex-start;
  padding: 10px;
}

.level-details-cashback {
  width: 30%;
  justify-content: flex-start;
  flex-direction: column;
}

.level-details-text {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0 15px 0 #595b5b5b;
}

.level-details-text-to {
  color: var(--grey-font);
  font-size: 12px;
  font-family: 'NunitoSans-SemiBold';
}

.level-details-spend-value {
  font-family: 'NunitoSans-Black';
  font-size: 16px;
  text-transform: uppercase;
}

.level-details-currency-iso {
  padding-left: 1px;
  font-size: 10px;
  font-family: 'NunitoSans-SemiBold';
  text-transform: uppercase;
}

.separator-upgrade-mantain {
  border-color: var(--grey-dividers);
}

.level-details-maintain {
  box-shadow: 2px 0px 40px 0px rgba(0, 0, 0, 0.15), 0px 2px 40px 0px rgba(0, 0, 0, 0.15);
}

.cashback-percentage {
  color: white;
  font-family: 'NunitoSans-Black';
  font-size: 26px;
  text-transform: uppercase;
}

.active-cashback {
  color: white;
  font-size: 13px;
  font-family: 'NunitoSans-Black';
}

.points-card {
  width: 100%;
  padding: 15px;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
}

.points-text {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
}

.day-symbol {
  position: unset;
  line-height: unset;
  vertical-align: unset;
}

.points-balance {
  font-family: 'NunitoSans-Black';
  font-size: 14px;
  text-transform: uppercase;
}

.points-available {
  display: flex;
  justify-content: space-between;
  height: 40%;
  align-items: center;
}

.points-available-points {
  font-size: 24px;
  font-family: 'NunitoSans-Black';
  text-transform: uppercase;
}

.points-available-pts {
  font-size: 12px;
  padding-left: 5px;
  font-family: 'NunitoSans-SemiBold';
}

.points-expiring {
  display: flex;
  justify-content: space-between;
  height: 40%;
  align-items: center;
}

.points-expire-date {
  font-size: 12px;
  font-family: 'NunitoSans-SemiBold';
}

@media screen and (max-width: 350px) {
  .cashback-percentage {
    color: white;
    font-family: 'NunitoSans-Black';
    font-size: 24px;
    text-transform: uppercase;
  }

  .active-cashback {
    color: white;
    font-size: 12px;
    font-family: 'NunitoSans-Black';
  }

  .level-details-cashback {
    width: 33%;
    justify-content: flex-start;
    flex-direction: column;
  }

  .level-details-text {
    width: 63%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0 15px 0 #595b5b5b;
  }
}

.settings-bg-content-top {
  height: 130px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.settings-bg-content-top-opacity {
  position: absolute;
  top: 0;
  height: 130px;
  width: 100%;
  background-color: white;
  opacity: 0.06;
}

.settings-header {
  height: 100%;
  width: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.settings-current-level-text {
  color: white;
  font-size: 16px;
  text-align: left;
}

.settings-current-text-bold {
  font-size: 20px;
  font-family: 'NunitoSans-Black';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.settings-title-section {
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  height: 24px;
  text-align: left;
}

.scroll-white-space {
  height: 80px;
  width: 100%;
}

.level-cashback {
  font-size: 20px;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.settings-header-current-points {
  width: 100px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  filter: brightness(85%);
  display: inline-block;
}

.settings-points-to-go {
  background-color: var(--grey-background);
  width: 175px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
}

.text-120-day-spend {
  font-family: NunitoSans-Bold;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 23px;
}


.settings-text {
  color: var(--grey-font-light-icons);
  font-size: 15px;
  font-family: 'NunitoSans-Regular';
  margin-bottom: 30px;
  margin-top: 15px;
}

.settings-levels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
}

.settings-levels-logos {
  position: relative;
  width: 85%;
  height: 100%;
  max-height: 100%;
}

.level-logos {
  height: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.level-cashback {
  font-size: 18px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
}

.level-cashback-terms-conditions {
  font-size: 10px;
  font-family: 'NunitoSans-Regular';
  text-decoration: underline;
}

.level-images {
  height: 75%;
}

.settings-levels-bar {
  position: relative;
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-person-name {
  height: 27px;
  color: white;
  font-size: 20px;
  font-family: 'NunitoSans-SemiBold';
  letter-spacing: 1.43px;
  display: inline;
}

.settings-activation-date {
  font-family: 'NunitoSans-Regular';
  font-size: 13px;
  font-weight: normal;
  height: 19px;
  letter-spacing: 1px;
  width: 177px;
}

.text-left {
  text-align: left;
}

.text-options {
  font-family: 'NunitoSans-Regular';
  font-size: 14px;
  height: 12px;
  letter-spacing: 1px;
}

.center-baseline {
  align-content: center;
  align-items: center;
}

.icon-settings-left {
  height: 19px;
  width: 19px;
}

.icon-settings-right {
  height: 16px;
  width: 16px;
  transform: rotate(180deg);
}

.settings-logout-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-bottom: 3px;
  cursor: pointer;
}

.icon-settings-header {
  width: 5rem;
  height: 5rem;
}

.settings-container {
  height: 500px;
  position: relative;
}

@media screen and (max-width: 325px) {
  .settings-person-name {
    font-size: 16px;
  }

  .settings-activation-date {
    font-size: 11px;
  }

  .icon-settings-header {
    width: 4rem;
    height: 4rem;
  }
}