@import '../constants.scss';

.gift-card-tile {
  position: relative;
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0;
}

.gift-card-tile-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(30, 36, 50, 0.2);
}

.gift-card-tile-logo {
  padding: 3px;
  border-radius: 50%;

  &-container {
    width: 3rem;
    margin-top: 15px;
    margin-left: 30px;
    margin-right: auto;
    margin-bottom: auto;
  }
}

.gift-card-status {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 20%;
  width: 100%;
  border-radius: 0 4px;
}

.gift-card-status-tag {
  padding: 0px 12px 0px;
  border-radius: 4px;
  opacity: 0.8;

  &-text {
    font-size: 11px;
    font-family: 'NunitoSans-Bold';
  }
}

.gift-card-status-opacity {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 0 4px;
  opacity: 0.70;
  background-color: white;
  z-index: 1;
}

.gift-card-status-remaining {
  font-size: 14px;
  font-family: 'NunitoSans-Bold';
  color: var(--grey-font);
}

.gift-card-status-use-by {
  font-size: 14px;
  font-family: 'NunitoSans-Regular';
  color: var(--grey-font);
}

@media screen and (max-width: 350px) {
  .gift-card-tile,
  .gift-card-tile-img {
    height: 150px;
  }

  .gift-card-status-use-by,
  .gift-card-status-remaining {
    font-size: 12px;
  }
}