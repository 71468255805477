@import './assets/css/main.scss';

@font-face {
  font-family: 'NunitoSans-Black';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-Black.ttf');
}

@font-face {
  font-family: 'NunitoSans-BlackItalic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-BlackItalic.ttf');
}

@font-face {
  font-family: 'NunitoSans-Bold';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: 'NunitoSans-BoldItalic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'NunitoSans-ExtraBold';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'NunitoSans-ExtraBoldItalic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'NunitoSans-ExtraLight';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraLight.ttf');
}

@font-face {
  font-family: 'NunitoSans-ExtraLightItalic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'NunitoSans-Italic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-Italic.ttf');
}

@font-face {
  font-family: 'NunitoSans-Light';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-Light.ttf');
}

@font-face {
  font-family: 'NunitoSans-LightItalic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-LightItalic.ttf');
}

@font-face {
  font-family: 'NunitoSans-Regular';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: 'NunitoSans-SemiBold';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: 'NunitoSans-SemiBoldItalic';
  src: url('./assets/fonts/Nunito_Sans/NunitoSans-SemiBoldItalic.ttf');
}

html {
  height: 100%;
  -ms-overflow-style: scrollbar;
  --scroll-bar: 0;
}

#root {
  position: relative;
}

body {
  margin: 0;
  font-family:'NunitoSans-Regular' !important;
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.view-boundaries-top {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.view-boundaries-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

/* Disables Long Press */
html {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
}

.long-press-enabled {
  -webkit-user-select: auto;
  -webkit-touch-callout: initial;
  user-select: auto;
}
