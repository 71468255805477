.chooseStartingTimeDate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &_month {
    margin-top: 20px;
  }

  &_daysWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
}
