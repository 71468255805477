@import '../constants.scss';

.footer-booking {
  padding-top: 5px;
}

.booking-title {
  flex: 0 0 65px;
}

.booking-container {
  margin-bottom: 95px;
}

.booking_timer {
  text-align: center;
  font-size: 11px;
  padding: 5px 0 5px 0;
}

.booking-footer {
  position: absolute;
  bottom:0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
}

.booking-footer-line {
  border-top: 1px solid var(--color-primary);
  width: 100%;
  padding-bottom: 10px;
}

.booking-footer-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.next-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.next-icon {
  filter: var(--filter-primary);
}

.back-icon-width{
  width: 2.5rem;
  height: 2.5rem;
}

.back-icon {
  cursor: pointer;
}

.timer {
  padding-bottom: 5px;
}

.timer-text {
  color: rgb(192, 57, 43);
}

.highlighted {
  text-decoration: underline;
}

.bookings-highlighted-text {
  font-weight: 800;

  &--level-4 {
    color: var(--color-level4);
  }

  &--level-3 {
    color: var(--color-level3);
  }

  &--level-2 {
    color: var(--color-level2);
  }

  &--level-1 {
    color: var(--color-primary);
  }
}

@media screen and (max-height: 740px) {
  .booking-title {
    flex: 0 0 50px;
  }

  .back-icon-width{
    width: 2rem;
    height: 2rem;
  }
}
