.burger-menu {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  background: white;
  border: none;
  width: 95%;
  height: 100%;
  cursor: pointer;
  padding: 1.25rem;
  z-index: 10;
  transition: transform .3s
  cubic-bezier(0, .52, 0, 1);
  padding-top: calc(1.25rem + constant(safe-area-inset-top));
  padding-top: calc(1.25rem + env(safe-area-inset-top));
}

.open {
  transform: translate3d(0vw, 0, 0);
}

.close {
  transform: translate3d(-100vw, 0, 0);
  overflow: hidden;
}

.menu-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.65);
}

.close-menu {
  filter: var(--filter-white);
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.menu-header {
  padding-top: 20px;
  padding-bottom: 10px;
}

.menu-header {
  display: flex;
  align-items: flex-start;
}

.menu-body {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.menu-tab {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  min-height: 60px;
}

.tab-info {
  display: flex;
  align-self: center;
  align-items: center;
}

.new-text {
  display: flex;
  background: rgb(94, 53, 84);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  font-family: 'NunitoSans-Bold';
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.menu-tab-text {
  color: white;
  font-size: 18px;
  align-self: center;
}

.menu-tab-text-selected {
  color: var(--color-primary);
  font-family: 'NunitoSans-SemiBold';
  font-size: 18px;
  align-self: center;
}

.menu-tab-text-soon {
  color: var(--grey-font-light-icons);
  font-size: 18px;
  align-self: center;
}

.tab-comming-soon {
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
  background: rgba(94, 53, 84, 0.2);
  border-radius: 25px;
  padding: 3px 15px;
}

.menu-footer {
  position: relative;
  height: auto;
  width: 100%;
  margin-top: 150px;
}

.menu-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-logo {
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 10px;
  filter: var(--filter-white);
}

.copyright {
  color: white;
  font-size: 18px;
}

.logout-warning {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.65);
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-warning-container {
  width: 70%;
  height: 200px;
  flex-direction: column;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.logout-warning-container-title {
  font-size: 15px;
  padding-bottom: 15px;
  font-family: 'NunitoSans-SemiBold';
}

.button-logout-secondary {
  padding: 5px;
  margin-right: 5px;
}

.button-logout-primary {
  padding: 5px;
  margin-left: 5px;
}

.button-logout-secondary:active {
  padding: 5px;
  margin-right: 5px;
}

.button-logout-primary:active {
  padding: 5px;
  margin-left: 5px;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
}

.menu-logo {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 10px;
}

.copyright {
  font-size: 16px;
}

.logout-warning-container {
  width: 80%;
}

@media screen and (max-width: 325px) {
  .menu-logo {
    width: 2.8rem;
    height: 2.8rem;
  }
}

@media screen and (max-width: 370px) {
  .menu-tab {
    min-height: 50px;
  }
}

@media screen and (min-width: 410px) {
  .menu-footer {
    margin-top: 250px;
  }
}
