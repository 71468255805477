@import '../constants.scss';

.survey-details-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  align-items: center;
  margin-bottom: 85px;
  width: 100%;
}

.survey-details-header {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.survey-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 0px;
  width: 100%;
  scroll-behavior: smooth;
}

.survey-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.survey-feedback-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'NunitoSans-SemiBold';
  height: 60px;
  font-size: 14px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  opacity: 0.7;
}

.survey-feedback-close-icon {
  width: 2rem;
  height: 2rem;
  filter: var(--filter-white);
}

.survey-details-section-title {
  font-size: 16px;
  font-family: 'NunitoSans-Bold';
}

.survey-details-service-text {
  font-size: 16px;
  font-family: 'NunitoSans-Regular';
  color: var(--color-primary);
}

.survey-details-text {
  font-size: 16px;
  font-family: 'NunitoSans-Regular';
  color: var(--grey-font);
}

.survey-details-heavy-separator {
  width: 100%;
  border-bottom: 2px solid var(--color-primary);
}

.survey-details-provider-image {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
}

.survey-details-shop-feedback {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.survey-details-tell-us-more-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  scrollbar-width: none;
  width: 100%;
}

.survey-details-close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}

.survey-details-close-icon {
  width: 2rem;
  height: 2rem;
}

// OVERRIDE RATING STARS

.survey-details-container .star-icon {
  width: 1.6rem;
  height: 1.6rem;
}

@media screen and (max-width: 350px) {
  .survey-feedback-close-icon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .survey-details-close-icon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .survey-feedback-info {
    height: 50px;
    font-size: 12px;
  }

  .survey-details-text {
    font-size: 14px;
  }
}