.slide-wallet-container {
  display: flex;
  flex-direction: column;
  width: 170px;
  height: auto;
  border-radius: 4px;
  margin-bottom: 40px;
}

.slide-wallet-content {
  height: 115px;
  width: 96%;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
}

.slide-wallet-content-title {
  font-family: 'NunitoSans-Light';
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 0.5);
}

.slide-wallet-content-text {
  font-family: 'NunitoSans-Regular';
  font-size: 16px;
  font-weight: 500;
  height: 23px;
  letter-spacing: 0px;
  color: white;
}

.slide-wallet-text-left {
  z-index: 2;
}

.slide-wallet-image {
  height: 35px;
  width: 35px;
  filter: var(--filter-white);
}

.slide-wallet-img {
  background-size: cover;
  background-repeat: no-repeat;
}


@media screen and (max-width: 350px) {
  .slide-wallet-container {
    width: 140px;
  }

  .slide-wallet-content-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 410px) {
  .slide-wallet-container {
    width: 190px;
  }
}