.dashboard-stamp-cards {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--grey-background);
    padding-bottom: calc(24px + constant(safe-area-inset-bottom));
    padding-bottom: calc(24px + env(safe-area-inset-bottom));
  }

  &-list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 24px;
    margin-bottom: calc(150px + constant(safe-area-inset-bottom));
    margin-bottom: calc(150px + env(safe-area-inset-bottom));
  }
}


.stamp-cards-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;

  &-filter {
    width: 100%;
    margin-bottom: 10px;

    input[type="radio"] {
      margin-left: auto;
    }
  }
}
