@import '../constants.scss';

.transaction-details-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 70px;
}

.transaction-details {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  width: 100%;
  background-color: var(--grey-background);
}

// Icons

.transaction-details-saleable-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.transaction-details-services-icon {
  width: 2rem;
  height: 2rem;
}

// Separators
.transaction-details-light-separator {
  border-bottom: 1px solid var(--grey-dividers);
}

.transaction-details-heavy-separator {
  border-bottom: 2px solid var(--grey-dividers);
}

.transaction-details-saleable {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

// Text

.transaction-details-large-title {
  font-size: 20px;
  color: var(--color-primary);
  font-family: 'NunitoSans-Bold';
}

.transaction-details-title {
  font-size: 18px;
  font-family: 'NunitoSans-Bold';
}

.transaction-details-subtitle {
  font-size: 16px;
  color: var(--grey-font);
  font-family: 'NunitoSans-SemiBold';
}

.transaction-details-regular-text {
  font-size: 16px;
  color: var(--grey-font);
  font-family: 'NunitoSans-Regular';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transaction-details-bold-text {
  font-size: 16px;
  color: var(--grey-font);
  font-family: 'NunitoSans-Bold';
}

.transaction-details-small-regular-text {
  font-size: 14px;
  color: var(--grey-font);
  font-family: 'NunitoSans-Regular';
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transaction-details-small-bold-text {
  font-size: 14px;
  color: var(--grey-font);
  font-family: 'NunitoSans-Bold';
}

.transaction-details-small-primary-text {
  font-size: 14px;
  font-family: 'NunitoSans-Bold';
}

.transaction-details-discount-color {
  color: var(--color-green);
}

.transaction-details-payments-container {
  display: flex;
  flex-direction: column;
  background-color: var(--grey-background);
  padding: 1rem;
  margin-bottom: 2rem;
}

// Points Card

.transaction-details-points-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  height: 100px;
  // border-radius: 3px;
  padding: 10px 15px;
}

.transaction-details-celebrate-icon {
  position: relative;
  top: -1.2rem;
  left: -0.75rem;
  width: 9rem;
  height: auto;
}

.transaction-details-no-points {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding-bottom: 15px;
}

.transaction-details-points-card-text {
  color: white;
  font-family: 'NunitoSans-SemiBold';
  font-size: 13px;
}

.win-points {
  display: flex;
  background-color: white;
  bottom: 0;
  left: 0;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  height: 150px;
  z-index: 1;
  width: 100%;
  box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.09);
}

.win-points-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1
}

.how-to-win-points-title {
  font-size: 17px;
  font-family: 'NunitoSans-Bold';
  padding-top: 3px;
}

.how-to-win-points-close-icon {
  width: 1.7rem;
  height: 1.7rem;
}

.how-to-win-points-text {
  font-size: 15px;
}


@media screen and (max-width: 350px) {
  .transaction-details-celebrate-icon {
    min-width: 5rem;
    width: 5rem;
    height: auto;
  }

  .transaction-details-section-title {
    font-size: 16px;
  }

  .transaction-details-large-title {
    font-size: 18px;
  }

  .transaction-details-title {
    font-size: 16px;
  }

  .transaction-details-subtitle {
    font-size: 14px;
  }

  .transaction-details-regular-text {
    font-size: 14px;
  }

  .transaction-details-bold-text {
    font-size: 14px;
  }

  .transaction-details-small-regular-text {
    font-size: 12px;
  }

  .transaction-details-small-bold-text {
    font-size: 12px;
  }

  .transaction-details-small-primary-text {
    font-size: 12px;
  }

  .transaction-details-points-card-text {
    font-size: 10px;
  }

  .win-points {
    height: 150px;
  }

  .how-to-win-points-title {
    font-size: 16px;
  }

  .how-to-win-points-text {
    font-size: 14px;
  }

  .transaction-details-points-card-container {
    min-height: 75px;
    height: 75px;
  }

  .transaction-details-no-points {
    font-size: 13px;
  }

  .transaction-details-celebrate-icon {
    min-width: 3.5rem;
    width: 3.5rem;
    height: auto;
  }

  .how-to-win-points-close-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .transaction-details-noPoints-icon {
    position: relative;
    top: -1.4rem;
    left: -0.6rem;
    width: 7rem;
    height: auto;
  }

  .transaction-details-celebrate-icon {
    min-width: 3.5rem;
    width: 7.5rem;
    height: auto;
  }

}

.transaction-points-container {
  padding-top: 15px;
}


@media screen and (min-width: 351px) and (max-width: 375px) {

  .transaction-details-noPoints-icon {
    position: relative;
    top: -1.7rem;
    left: -0.6rem;
    width: 8rem;
    height: auto;
  }

}

@media screen and (min-width: 319px) and (max-width:349px) {

  .transaction-details-noPoints-icon {
    position: relative;
    top: -1.9rem;
    left: -0.6rem;
    width: 7rem;
    height: 8rem;
  }

}

@media screen and (min-width: 376px) and (max-width:450px) {

  .transaction-details-noPoints-icon {
    position: relative;
    top: -1.7rem;
    left: -0.6rem;
    width: 8rem;
    height: 9rem;
  }

}

.reward-border-lvl1 {
  border: 3px solid var(--color-primary);
}

.reward-border-lvl2 {
  border: 3px solid var(--color-level2);
}

.reward-border-lvl3 {
  border: 3px solid var(--color-level3);
}

.reward-border-lvl4 {
  border: 3px solid var(--color-level4);
}