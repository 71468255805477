@import './constants.scss';

button:focus {
  outline: none;
}

.scrollbar-none {
  scrollbar-width: none;
}

.card-tile-pending {
  background-color: var(--color-yellow);
}

.card-tile-completed {
  background-color: var(--color-green);
}

.card-tile-upcoming {
  background-color: var(--color-primary);
}

.card-tile-closed {
  background-color: var(--color-grey);
}

.card-tile-canceled {
  background-color: var(--color-cancel);
}

.card-tile-no-show {
  background-color: var(--color-grey);
}

.s-alert-show {
  text-align: center;
}

.grey-background {
  background-color: var(--grey-background);
}

.grey-border {
  color: var(--grey-dividers);
}

.container-center {
  text-align: -moz-center;
  text-align: -webkit-center;
}

.button-primary {
  border-radius: .1875rem;
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;
  font-family: 'NunitoSans-Bold';
  font-size: 20px;
}

.button-primary-lvl1 {
  border-radius: .1875rem;
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20px;
  background-color: var(--color-primary);
  border: .0625rem solid var(--color-primary);
}
.button-primary-lvl2 {
  border-radius: .1875rem;
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20px;
  background-color: var(--color-level2);
  border: .0625rem solid var(--color-level2);
}

.button-primary-lvl3 {
  border-radius: .1875rem;
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20px;
  background-color: var(--color-level3);
  border: .0625rem solid var(--color-level3);
}

.button-primary-lvl4 {
  border-radius: .1875rem;
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20px;
  background-color: var(--color-level4);
  border: .0625rem solid var(--color-level4);
}

.button-sign-in {
  background-color: var(--color-primary);
  border-radius: 4px;
  border: .0625rem solid var(--color-primary);
  color: white;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 16px;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--color-primary) !important;
}

.MuiPickersDay-current {
  color: var(--color-primary) !important;
}

.MuiPickersDay-daySelected  {
  color: white !important;
  background-color: var(--color-primary) !important;
}

.MuiButton-textPrimary {
  color: var(--color-primary) !important;
}

.MuiTypography-colorPrimary {
  color: var(--color-primary) !important;
}

.MuiInput-underline:before {
  display: none !important;
}

.MuiInput-underline:after {
  display: none !important;
}

.MuiInputBase-root {
  font-size: 14px !important;
  position: relative;
  font-size: 1rem;
  font-family: unset !important;
  line-height: unset !important;
}

.MuiFormHelperText-root.Mui-error {
  display: none !important;
}

.MuiInputBase-input {
  height: unset !important;
  padding: 0px !important;
}

.button-grey {
  background: rgb(177, 188, 206);
  border-radius: .1875rem;
  border: .0625rem solid rgb(177, 188, 206);
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  pointer-events: none;
  font-family: 'NunitoSans-Bold';
  font-size: 20px;
}

.button-sign-in-grey {
  background: var(--color-grey);
  border-radius: 7px;
  border: .0625rem solid var(--color-grey);
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  pointer-events: none;
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

.button-disable {
  background-color: #DFDFDF;
  border-radius: .1875rem;
  border: .0625rem solid #C6C6C6;
  min-width: 100px;
  color: #B9B8B8;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20px;
}

.button-primary:active {
  background-color: white;
  border-radius: .1875rem;
  min-width: 100px;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20x;
}

.button-secondary {
  background-color: white;
  border-radius: .1875rem;
  min-width: 100px;
  transition: .2s;
  padding: 10px;
  font-family: 'NunitoSans-Bold';
  font-size: 20px;
}

.button-secondary:active {
  border-radius: .1875rem;
  border: .0625rem solid white;
  min-width: 100px;
  color: white;
  transition: .2s;
  padding: 10px;

  font-family: 'NunitoSans-Bold';
  font-size: 20px;

  .next-icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
  }
}

.separator {
  // border-top: 2px solid var(--grey-dividers);
  width: 100%;
  // margin: 0px 0px 15px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.separator-line {
  border-top: 1px solid var(--grey-dividers);
  width: 100%;
}

.separator-line-bold {
  border-top: 2px solid var(--grey-dividers);
  width: 100%;
}

.input-primary {
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  background-color: var(--grey-background);
  padding: 8px 5px 8px 35px;
}

.custom-checkbox-container-lvl1 {
  display: flex;
  position: relative;
  cursor: pointer;
  height: 20px;
  padding-right: 1.25rem;

  // Hide the default checkboxes
  input[type=checkbox] {
    display: none;
  }
}

.custom-checkbox-container-lvl2 {
  display: flex;
  position: relative;
  cursor: pointer;
  height: 20px;
  padding-right: 1.25rem;

  // Hide the default checkboxes
  input[type=checkbox] {
    display: none;
  }
}

.custom-checkbox-container-lvl3 {
  display: flex;
  position: relative;
  cursor: pointer;
  height: 20px;
  padding-right: 1.25rem;

  // Hide the default checkboxes
  input[type=checkbox] {
    display: none;
  }
}
.custom-checkbox-container-lvl4 {
  display: flex;
  position: relative;
  cursor: pointer;
  height: 20px;
  padding-right: 1.25rem;

  // Hide the default checkboxes
  input[type=checkbox] {
    display: none;
  }
}

.custom-checkbox-lvl1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
}

.custom-checkbox-container-lvl1 input:checked ~ .custom-checkbox-lvl1 {
  background-color: var(--color-primary) !important;
}

.custom-checkbox-lvl2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--color-level2);
}

.custom-checkbox-container-lvl2 input:checked ~ .custom-checkbox-lvl2 {
  background-color: var(--color-level2) !important;
}

.custom-checkbox-lvl3 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--color-level3);
}

.custom-checkbox-container-lvl3 input:checked ~ .custom-checkbox-lvl3 {
  background-color: var(--color-level3) !important;
}

.custom-checkbox-lvl4 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--color-level4);
}

.custom-checkbox-container-lvl4 input:checked ~ .custom-checkbox-lvl4 {
  background-color: var(--color-level4) !important;
}

.custom-checkbox-lvl1:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-lvl2:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-lvl3:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-lvl4:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container-lvl1 input:checked ~ .custom-checkbox-lvl1:after {
  display: block;
}

.custom-checkbox-container-lvl1 .custom-checkbox-lvl1:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid rgb(255,255,255);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox-container-lvl2 input:checked ~ .custom-checkbox-lvl2:after {
  display: block;
}

.custom-checkbox-container-lvl2 .custom-checkbox-lvl2:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid rgb(255,255,255);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox-container-lvl3 input:checked ~ .custom-checkbox-lvl3:after {
  display: block;
}

.custom-checkbox-container-lvl3 .custom-checkbox-lvl3:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid rgb(255,255,255);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox-container-lvl4 input:checked ~ .custom-checkbox-lvl4:after {
  display: block;
}

.custom-checkbox-container-lvl4 .custom-checkbox-lvl4:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 11px;
  border: solid rgb(255,255,255);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loader-container {
  position: absolute;
  z-index: 1;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24rem;
}

.loader {
  border: 11px solid var(--grey-dividers);
  border-radius: 50%;
  border-top: 11px solid #dddddd;
  width: 85px;
  height: 85px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite; /* Safari */
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.s-alert-box {
  margin: 10px auto;
  width: 95% !important;
  border-radius: 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header {
  min—height: 80px;
}

::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

[role='button'] {
  outline: none;
}

.sub-header {
  min-height: 25px;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 1;

  &-afterRender {
    position: absolute;
    right: 0;
    margin-right: 1.5rem;
  }
}

.sub-header-title {
  color: white;
  font-family: 'NunitoSans-Bold';
  font-size: 20px;
  height: 100%;
}

.primary-card {
  display: flex;
  padding: 30px 20px;
  border-radius: 3px;
  box-shadow: 2px 0px 40px 0px rgba(0, 0, 0, 0.05),
  0px 2px 40px 0px rgba(0, 0, 0, 0.05);
  min-height: 193px;
  height: 193px;
  width: 100%;
}

.primary-small-card {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 2px 0px 40px 0px rgba(0, 0, 0, 0.05),
  0px 2px 40px 0px rgba(0, 0, 0, 0.05);
  min-height: 100%;
  width: 100%;
}

.opa {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  border-radius: 3px;
}

.small-card-title {
  font-family: 'NunitoSans-Black';
  font-size: 30px;
  z-index: 1;
  padding: 10px 10px 0px 10px;
}

.small-card-subtitle {
  z-index: 1;
  font-size: 17px;
  padding: 0px 10px 10px 10px;
}

.small-card {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: 0px 0 15px 0 #595B5B5B;
  min-height: 86px;
  width: 100%;
}

.footer {
  position: fixed;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  bottom: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px -2px 4px 0px rgba(232, 232, 232, 0.59);
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.items-center {
  align-items: center;
}

.w-max {
  width: max-content;
}

.pass-icon-new {
  position: absolute;
  top: 43px;
  right: 17px;
}

.label-pwd {
  font-family: 'NunitoSans-Bold';
  font-size: 14px;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
}

.card-img {
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #512424;
  box-shadow: 0px 5px 10px 0px rgba(30, 36, 50, 0.2);
  border-radius: 4px;
}

@media screen and (max-width: 350px) {
  .header {
    height: 75px;
  }

  .sub-header-title {
    font-size: 20px;
  }

  .primary-card {
    padding: 20px 10px;
    min-height: 163px;
    height: 163px;
    margin-bottom: 20px;
  }

  .small-card {
    min-height: 76px;
  }

  .small-card-title {
    font-size: 26px;
    padding: 5px 5px 0px 5px;
  }

  .small-card-subtitle {
    font-size: 14px;
    padding: 0px 5px 5px 5px;
  }
}

.bold {
  font-family: 'NunitoSans-Bold';
}

.light {
  font-family: 'NunitoSans-Light';
}
