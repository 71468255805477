.select-box {
  background-color: white;
  width: 100%;
  padding: 24px 20px 8px 20px;

  &--readOnly {
    padding: 0 !important;
  }

  &-search {
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      margin-left: 15px;

      svg {
        width: 40px;
      }

      &--level-1 {
        svg {
          fill: var(--color-primary);
        }
      }

      &--level-2 {
        svg {
          fill: var(--color-level2);
        }
      }

      &--level-3 {
        svg {
          fill: var(--color-level3);
        }
      }

      &--level-4 {
        svg {
          fill: var(--color-level4);
        }
      }
    }

    input {
      width: 100%;
      height: 100%;
      padding: 20px;
      text-align: left;
      outline: none;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    padding: 0.4rem 1rem;
    border-radius: 4px;
    height: 55px;
    font-size: 14px;

    &--label {
      text-transform: capitalize;
    }

    &--readOnly {
      border: none !important;
      padding: 0 !important;
    }

    svg {
      margin-left: auto;
    }

    &--level-1 {
      svg {
        fill: var(--color-primary);
      }

      color: var(--color-primary);
      border: 1px solid var(--color-primary);

      &--active {
        background-color: var(--color-primary);
        color: white;

        svg {
          fill: white;
          transform: rotate(180deg);
        }
      }
    }

    &--level-2 {
      svg {
        fill: var(--color-level2);
      }

      color: var(--color-level2);
      border: 1px solid var(--color-level2);

      &--active {
        background-color: var(--color-level2);
        color: white;

        svg {
          fill: white;
          transform: rotate(180deg);
        }
      }
    }

    &--level-3 {
      svg {
        fill: var(--color-level3);
      }

      color: var(--color-level3);
      border: 1px solid var(--color-level3);

      &--active {
        background-color: var(--color-level3);
        color: white;

        svg {
          fill: white;
          transform: rotate(180deg);
        }
      }
    }

    &--level-4 {
      svg {
        fill: var(--color-level4);
      }

      color: var(--color-level4);
      border: 1px solid var(--color-level4);

      &--active {
        background-color: var(--color-level4);
        color: white;

        svg {
          fill: white;
          transform: rotate(180deg);
        }
      }
    }
  }

  &-options {
    margin-top: 0.2rem;
    border-radius: 4px;
    height: 100%;
    overflow: scroll;
    scrollbar-width: none;

    &--active {
      border-width: 2px;
      border-style: solid;
    }

    &--level-1 {
      border-color: var(--color-primary);
    }

    &--level-2 {
      border-color: var(--color-level2);
    }

    &--level-3 {
      border-color: var(--color-level3);
    }

    &--level-4 {
      border-color: var(--color-level4);
    }
  }

  &-item {
    padding: 0.5rem 1rem;

    &--level-1 {
      border-bottom: 2px solid var(--color-primary);

      &--active {
        background-color: #7244673D;
        color: var(--color-primary);
      }
    }

    &--level-2 {
      border-bottom: 2px solid var(--color-level2);

      &--active {
        background-color: #98458C3D;
        color: var(--color-level2);
      }
    }

    &--level-3 {
      border-bottom: 2px solid var(--color-level3);

      &--active {
        background-color: #C252813D;
        color: var(--color-level3);
      }
    }

    &--level-4 {
      border-bottom: 2px solid var(--color-level4);

      &--active {
        background-color: #D882823D;
        color: var(--color-level4);
      }
    }

    &:last-child {
      border-bottom: 0px !important;
    }
  }
}
