@import '../constants.scss';

.bookings-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 70px;
  height: 100%;
}

.bookings-in-title {
  color: var(--color-primary);
  font-size: 22px;
  font-family: 'NunitoSans-Bold';
  padding: 15px 0px;
}

.bookings-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0px;
}

.separator-text {
  color: var(--grey-dividers);
  font-size: 16px;
  padding: 0px 20px;
}

@media screen and (max-width: 350px) {
}
