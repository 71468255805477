.vouchers-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: calc(70px + constant(safe-area-inset-bottom));
  margin-bottom: calc(70px + env(safe-area-inset-bottom));
  height: 100%;
  background-color: var(--grey-background);
}

.bookings-load-more {
  color: var(--color-primary);
  font-size: 16px;
  margin-left: 5px;
  padding-top: 1px;
}

.bookings-load-more-icon {
  width: 1rem;
  height: 1rem;
  filter: var(--filter-primary);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.vouchers-in-title {
  color: var(--color-primary);
  font-size: 22px;
  font-family: 'NunitoSans-Bold';
  padding: 15px 0px;
}

.vouchers-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.separator-text {
  color: var(--grey-dividers);
  font-size: 16px;
  padding: 0px 20px;
}

.voucher-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;

  &-filter {
    width: 100%;
    margin-bottom: 10px;

    input[type="radio"] {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 350px) {
  .primary-small-cards-container {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .bookings-load-more {
    font-size: 14px;
    margin-left: 5px;
    padding-top: 1px;
  }

  .bookings-load-more-icon {
    width: 0.8rem;
    height: 0.8rem;
  }
}