@import "../constants.scss";

.level {
  width: 100%;
  border-radius: 3px;
  display: flex;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  max-height: 200px;
  margin-bottom: 20px;
}

.level-no-filter {
  width: 40%;
}

.level-information {
  width: 55%;
  display: flex;
  height: 100%;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.level-text {
  display: flex;
  flex-direction: column;
}

.level-text-awesome {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: "NunitoSans-Black";
  text-transform: uppercase;
}

.level-text-member {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: "NunitoSans-Regular";
}

.level-learn-more {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.level-learn-more-icon {
  width: 1.5rem;
  filter: var(--filter-white);
}

.level-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 200px;
  margin-bottom: 20px;
}

.level-details-box {
  border-radius: 3px;
  display: flex;
  max-height: 100px;
  align-items: flex-start;
  padding: 10px;
}

.level-details-cashback {
  width: 30%;
  justify-content: flex-start;
  flex-direction: column;
}

.level-details-text {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0 15px 0 #595b5b5b;
}

.level-details-text-to {
  color: var(--grey-font);
  font-size: 12px;
  font-family: "NunitoSans-SemiBold";
}

.level-details-spend-value {
  font-family: "NunitoSans-Black";
  font-size: 16px;
  text-transform: uppercase;
}

.level-details-currency-iso {
  padding-left: 1px;
  font-size: 10px;
  font-family: "NunitoSans-SemiBold";
  text-transform: uppercase;
}

.separator-upgrade-mantain {
  border-color: var(--grey-dividers);
}

.level-details-maintain {
  box-shadow: 2px 0px 40px 0px rgba(0, 0, 0, 0.15), 0px 2px 40px 0px rgba(0, 0, 0, 0.15);
}

.cashback-percentage {
  color: white;
  font-family: "NunitoSans-Black";
  font-size: 26px;
  text-transform: uppercase;
}

.active-cashback {
  color: white;
  font-size: 13px;
  font-family: "NunitoSans-Black";
}

.points-card {
  width: 100%;
  padding: 15px;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
}

.points-text {
  font-size: 14px;
  font-family: "NunitoSans-Regular";
}

.day-symbol {
  position: unset;
  line-height: unset;
  vertical-align: unset;
}

.points-balance {
  font-family: "NunitoSans-Black";
  font-size: 14px;
  text-transform: uppercase;
}

.points-available {
  display: flex;
  justify-content: space-between;
  height: 40%;
  align-items: center;
}

.points-available-points {
  font-size: 24px;
  font-family: "NunitoSans-Black";
  text-transform: uppercase;
}

.points-available-pts {
  font-size: 12px;
  padding-left: 5px;
  font-family: "NunitoSans-SemiBold";
}

.points-expiring {
  display: flex;
  justify-content: space-between;
  height: 40%;
  align-items: center;
}

.points-expire-date {
  font-size: 12px;
  font-family: "NunitoSans-SemiBold";
}

@media screen and (max-width: 350px) {
  .cashback-percentage {
    color: white;
    font-family: "NunitoSans-Black";
    font-size: 24px;
    text-transform: uppercase;
  }

  .active-cashback {
    color: white;
    font-size: 12px;
    font-family: "NunitoSans-Black";
  }

  .level-details-cashback {
    width: 33%;
    justify-content: flex-start;
    flex-direction: column;
  }

  .level-details-text {
    width: 63%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0 15px 0 #595b5b5b;
  }
}

.loyalty-program-bg-content-top {
  height: 200px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.loyalty-program-bg-content-top-opacity {
  position: absolute;
  top: 0;
  height: 200px;
  width: 100%;
  background-color: white;
  opacity: 0.06;
}

.loyalty-program-header {
  height: 200px;
}

.loyalty-program-current-level-logo {
  height: 135px;
  filter: var(--filter-white);
}

.loyalty-program-current-level-text {
  color: white;
  font-size: 16px;
  text-align: left;
}

.loyalty-program-current-text-bold {
  font-size: 20px;
  font-family: "NunitoSans-Black";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loyalty-title-section {
  font-family: "NunitoSans-Bold";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  height: 24px;
  text-align: left;
}

.scroll-white-space {
  height: 80px;
  width: 100%;
}

.level-cashback {
  font-size: 20px;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.loyalty-program-header-current-points {
  width: 100px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  filter: brightness(85%);
  display: inline-block;
}

.loyalty-program-points-to-go {
  background-color: var(--grey-background);
  width: 175px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
}

.sc-gauge {
  width: 200px;
}

.sc-background {
  position: relative;
  height: 95px;
  margin-bottom: 10px;
  background-color: #eaeaea;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  text-align: center;
}

.sc-mask {
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  height: 80px;
  background-color: var(--grey-background);
  border-radius: 150px 150px 0 0;
}

.sc-percentage {
  position: absolute;
  top: 100px;
  left: -200%;
  width: 400%;
  height: 400%;
  margin-left: 100px;
}

.sc-percentage {
  transform-origin: top center;
}

.sc-min {
  float: left;
}

.sc-max {
  float: right;
}

.sc-value {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
}

.sc-value-top {
  position: absolute;
  font-family: "NunitoSans-Bold";
  top: 44%;
  left: 0;
  width: 100%;
  font-size: 18px;
}
.sc-value-bottom {
  position: relative;
  top: -33px;
  left: 0;
  width: 100%;
  line-height: 19px;
  font-size: 14px;
}

.text-120-day-spend {
  font-family: NunitoSans-Bold;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 23px;
}


.loyalty-program-text {
  color: var(--grey-font-light-icons);
  font-size: 15px;
  font-family: 'NunitoSans-Regular';
  margin-bottom: 30px;
  margin-top: 15px;
}

.loyalty-program-levels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
}

.terms-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  z-index: 1
}

.loyalty-program-levels-logos {
  position: relative;
  width: 85%;
  height: 100%;
  max-height: 100%;
}

.level-logos {
  height: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.level-cashback {
  font-size: 18px;
  font-family: 'NunitoSans-SemiBold';
  color: var(--color-primary);
}

.level-cashback-terms-conditions {
  font-size: 10px;
  font-family: 'NunitoSans-Regular';
  text-decoration: underline;
}

.level-images {
  height: 75%;
}

.loyalty-program-levels-bar {
  position: relative;
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  height: 100%;
  width: 17px;
  border-radius: 50px;
}

.bar-bullet {
  border-radius: 50px;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  border: 4px solid white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  height: 13px;
  width: 13px;
}

.bar-arrow {
  position: absolute;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  right: 4px;
}

@media screen and (max-width: 350px) {
  .loyalty-program-levels {
    height: 400px;
  }

  .loyalty-program-levels-logos {
    position: relative;
    width: 90%;
    height: 100%;
    max-height: 100%;
  }

  .level-cashback {
    font-size: 13px;
    font-family: 'NunitoSans-SemiBold';
  }

  .loyalty-program-levels-bar {
    position: relative;
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .level-images {
    height: 80%;
  }

  .bar {
    height: 100%;
    width: 13px;
    border-radius: 50px;
  }

  .bar-bullet {
    border-radius: 50px;
    position: absolute;
    background: rgba(0, 0, 0, 0);
    border: 4px solid white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
    height: 10px;
    width: 10px;
  }

  .bar-arrow {
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    right: -6px;
  }
}


.sc-gauge-info-section {
  margin-top: -25px;
}