.bookings-starting-time {
  height: 60%;

  &-no-staff {
    text-align: center;
    padding: 15px;
  }

  &-slot-options {
    height: 80%;
  }

  &-for-slot {
    height: 80%;
  }
}

.bookings-starting-time-no-availability {
  text-align: center;
  padding: 20px;
}
