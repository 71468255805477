.subtitle {
  color: var(--grey-font-light-icons);
  font-size: 18px;
  display: flex;
  flex: 0 0 50px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rs-slider {
  width: 100%
}

.body-container {
  flex: 1;
  min-height: 0px;
  margin-bottom: 40px;
}

@media screen and (max-height: 740px) {
  .subtitle {
    font-size: 15px;
    display: flex;
    flex: 0 0 40px;
  }
}

