.impersonation-list-container {
  overflow: scroll;
}

.client-card {
  width: 90%;
  margin-left: 5%;
  box-shadow: 0 0 8px 0 rgba(177,188,206,0.45);
  margin-bottom: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.client-details {
  width: 80%;
}

.client-detail-text {
  font-size: 14px;
  color: var(--grey-font);
  padding-left: 5px;
}

.client-detail-icon {
  filter: var(--filter-grey);
  width: 1.5rem;
  height: 1.5rem;
}

.client-detail-inverted-text {
  font-size: 14px;
  color: var(--white-font);
  padding-left: 5px;
}

.client-detail-inverted-icon {
  filter: var(--filter-white);
  width: 1.5rem;
  height: 1.5rem;
}

.impersonate-button {
  border-radius: .1875rem;
  min-width: 90px;
  height: 45px;
  color: white;
  transition: .2s;
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
  margin: auto;
}

.search-container {
  padding-top: 5px;
  padding-bottom: 7px;
}