.post-verification {
  &-text {
    font-size: 16px;
    color: var(--grey-font);
    font-family: 'NunitoSans-Light';
  }

  &-back-icon {
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 25px;
  }
}