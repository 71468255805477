@import '../constants.scss';

.booking-line-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.title {
  color: var(--color-primary);
  font-size: 22px;
  font-family: 'NunitoSans-Bold';
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.booking-lines {
  height: 3px;
  margin-top: -1px;
  background-color: var(--grey-dividers);
}

.left-icon-width{
  width: 1rem;
  height: 1rem;
}

.calendar-clock {
  padding: 5px;
  margin-right: 2.5%;
}

@media screen and (max-height: 740px) {
  .title {
    color: var(--color-primary);
    font-size: 20px;
    font-family: 'NunitoSans-Bold';
    align-items: center;
  }
}

