.booking-timing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  height: 100%;
  padding: 10px;
}

.booking-timing-options-read-only {
  align-items: center;
  font-size: 13px;
  color: #595959;
}

.booking-timing-options-starting-time-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px 10px 50px;
  border-radius: 4px;

  &--save {
    color: white;

    &--level-4 {
      background-color: var(--color-level4);
    }

    &--level-3 {
      background-color: var(--color-level3);
    }

    &--level-2 {
      background-color: var(--color-level2);
    }

    &--level-1 {
      background-color: var(--color-primary);
    }
  }
}

.booking-timing-options-modal-wrapper {
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.booking-timing-options-modal-time-selector {
  height: 80%;
}

.booking-timing-options-time-selector--readOnlyHeader {
  height: 0px;
}

.booking-timing-options-title {
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-weight: bold;

  &--level-4 {
    color: var(--color-level4);
  }

  &--level-3 {
    color: var(--color-level3);
  }

  &--level-2 {
    color: var(--color-level2);
  }

  &--level-1 {
    color: var(--color-primary);
  }
}

.booking-timing-options-time-selector-wrapper {
  height: 60%;
  width: 100%;

  &--readOnly {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
