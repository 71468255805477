@import '../constants.scss';

.gift-cards-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 70px;
  height: 100%;
  background-color: var(--grey-background);
}