.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
  padding: 4px;
  border-radius: 50%;
  margin-left: auto;

  &:after,
  &:before {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }

  &-bg {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    background-color: #838383;
    border-radius: 40px;
    transition: all .1s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 2;
    opacity: 0;
  }

  &-switch {
    position: relative;
    top: -3px;
    width: 22px;
    height: 22px;
    margin-left: -2px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5),
      0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    transition: all .1s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
}

.toggle-switch-input:checked~.toggle-switch-switch {
  margin-left: 18px;
}
