.category_services_popup {
  height: 70%;
  width: 90%;
  padding-left: 0 !important;
  background-color: white;

  &--autoHeight {
    height: auto !important;
  }
}

.category_services_popup_section {
  height: 100%;
}

.category_services_popup_footer {
  color: white;
  text-align: center;
  font-size: 0.7em;
  margin-top: 0.5em;
}

.category_services_popup_footer_highlight {
  text-decoration: underline;
}

.category_services_popup_service_name {
  max-width: 70%;
}

.category_services_popup_section_header {
  color: white;
  width: 100%;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  text-align: center;
  font-weight: bold;
}
