@import '../constants.scss';

.booking-details-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 70px;
}

.booking-details {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  width: 100%;
  background-color: var(--grey-background);
}

.booking-details-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 250px;
  height: 250px;
  width: 100%;
}

.booking-details-image-gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  // background: linear-gradient(-180deg, rgb(105, 67, 96) 0%, rgba(106, 68, 97, 0.23) 90%, rgba(106, 68, 97, 0.06) 95%, rgba(106, 68, 97, 0) 100%);
}

.booking-details-image {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: -1;
}

.booking-details-image-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 20px;
  position: absolute;
}

.booking-details-image-title {
  color: white;
  font-size: 24px;
  font-family: 'NunitoSans-Bold';
}

.booking-details-image-subtitle {
  color: white;
  font-size: 18px;
  font-family: 'NunitoSans-Bold';
}

.booking-details-calendar-icon {
  width: 2rem;
  height: 2rem;
  filter: var(--filter-white);
}

.booking-details-clock-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.booking-details-services-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.booking-details-duration-icon {
  width: 1.4rem;
  height: 1.4rem;
  filter: var(--filter-primary);
}

.button-cancel {
  display: flex;
  justify-content: center;
  color: var(--color-cancel);
  transition: .2s;
  padding: 10px;
  font-family: 'NunitoSans-Bold';
  text-decoration: underline;
  font-size: 20px;
  text-underline-offset: 3px;
}

.button-cancel-icon {
  width: 2rem;
  height: 2rem;
  filter: var(--filter-red);
}

.booking-details-duration-text {
  font-family: 'NunitoSans-SemiBold';
  font-size: 15px;
  color: var(--color-primary);
}

.booking-details-text {
  font-family: 'NunitoSans-SemiBold';
  font-size: 16px;
  color: var(--grey-font);
}

.booking-details-text-title {
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

.booking-details-service-provider-name {
  color: var(--grey-font);
  font-family: 'NunitoSans-Regular';
  font-size: 16px;
}

.booking-details-row-header {
  display: flex;
  align-items: center;
}

.booking-details-row-separator {
  border-bottom: 2px solid rgb(221, 221, 221);
}

.provider-image {
  width: 50px;
  height: 50px;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
  margin-right: 15px;
}

@media screen and (max-width: 350px) {
  .booking-details-image-container {
    min-height: 220px;
    width: 100%;
  }

  .booking-details-booking-icon {
    margin-right: 5px;
  }

  .booking-details-calendar-icon {
    width: 2rem;
    height: 2rem;
    margin-left: -7px;
  }

  .booking-details-duration-icon {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: -7px;
  }

  .booking-details-clock-icon {
    width: 2rem;
    height: 2rem;
  }

  .booking-details-services-icon {
    width: 2rem;
    height: 2rem;
  }

  .booking-details-location {
    font-size: 13px;
  }

  .booking-details-text-title {
    font-size: 16px;
  }

  .booking-details-text-service {
    font-size: 13px;
  }

  .booking-details-text-time {
    font-size: 13px;
  }

  .booking-details-duration-text {
    font-size: 12px;
  }

  .booking-details-text {
    font-size: 13px;
  }

  .data-text {
    font-size: 13px;
  }

  .booking-details-image-title {
    font-size: 22px;
  }

  .booking-details-image-subtitle {
    font-size: 16px;
  }

  .button-cancel {
    font-size: 18px;
  }

  .button-cancel-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
