.slide-treat-container {
  min-height: 140px;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;
  z-index: 1;
  margin-right: 15px;
}

.slide-treat-section {
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: var(--color-primary);
  height: 24px;
}

.slide-treat-content {
  height: 155px;
  width: 100%;
  background-color:var(--color-anti-flash-white) ;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
}

.slide-treat-content-title {
  font-size: 13px;
  font-weight: 800;
  color: var(--color-primary);
  font-family: 'NunitoSans-ExtraBold';
}

.slide-treat-content-text {
  font-size: 11px;
}

.slide-treat-text-left{
  flex-direction: column;
  z-index: 2;
  width: 50%;
}

.slide-treat-badge-expiration {
  color: white;
  font-family: 'NunitoSans-Bold';
  font-size: 10px;
  font-weight: bold;
  height: 14px;
  letter-spacing: 0px;
  width: 125px;
  border-radius: 8px;
  text-align: center;
  opacity: 0.6;
}

.slide-treat-img {
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
