.dashboard-grey-background-color {
  background-color: var(--grey-background);
}

.card-tile-title {
  font-size: 18px;
  width: 100%;
  font-family: 'NunitoSans-Bold';
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-tile-subtitle {
  width: 100%;
  font-size: 16px;
  white-space: 'break-spaces';
}

.dashboard-back-icon-width {
  width: 1rem;
  height: 1rem;
}

.dashboard-back-icon {
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 2;
}

.no-items {
  display: flex;
  justify-content: center;
  color: var(--grey-font);
  margin-top: 15px;
  margin-bottom: 33px;
  font-size: 16px;
}

.upcoming-bookings-info {
  display: flex;
  justify-content: center;
  color: var(--grey-font);
  font-size: 12px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}

@media screen and (max-width: 350px) {
  .card-tile-title {
    font-size: 16px;
    font-family: 'NunitoSans-Bold';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-tile-subtitle {
    font-size: 14px;
    white-space: 'break-spaces';
  }
}

.pagination-on-top {
  top: 0;
  bottom: auto !important;
}
