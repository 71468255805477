@import '../constants.scss';

.Custom__Toast_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  z-index: 9999;
}

.Custom__Toast_body {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 100%;
  padding: 10px;
  color: white;
}

.Custom__Toast_message {
  max-width: 80%;
  margin: 0 1rem;
  text-align: justify;
}

.Custom__Toast_type-icon {
  width: 2rem;
  height: 2rem;
  filter: var(--filter-white);
}

.Custom__Toast_close-icon {
  width: 1.5rem;
  height: 1.5rem;
  filter: var(--filter-white);
}

.Custom__Toast_error {
  background-color: var(--color-cancel);
}

.Custom__Toast_info {
  background-color: var(--color-info);
}

.Custom__Toast_success {
  background-color: var(--color-green);
}

.Custom__Toast_warning {
  background-color: var(--color-warning);
}

.Toastify__toast {
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  padding: 0;
}

.Toastify__toast-body {
  padding: 0;
}