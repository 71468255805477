@import '../constants.scss';

:root {
  --semicircle-width: 20px; // smaller than height to make an ellipsis shape
  --semicircle-height: 30px;
}

.voucher-card-tile-parent {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.voucher-card-tile-parent:after,
.voucher-card-tile-parent:before {
  content: '';
  position: absolute;
  top: calc(50% - (var(--semicircle-height) / 2));
  width: var(--semicircle-width);
  height: var(--semicircle-height);
  background: var(--grey-background);
  border-radius: 50%;
}

.voucher-card-tile-img:after,
.voucher-card-tile-img:before {
  content: '';
  position: absolute;
  top: calc(50% - (var(--semicircle-height) / 2));
  width: var(--semicircle-width);
  height: var(--semicircle-height);
  background: var(--grey-background);
  border-radius: 50%;
}

.voucher-card-tile-img:before {
  left: calc(0px - var(--semicircle-width) / 2)
}

.voucher-card-tile-img:after {
  right: calc(0px - var(--semicircle-width) / 2)
}

.voucher-card-tile-parent:before {
  left: calc(0px - var(--semicircle-width) / 2)
}

.voucher-card-tile-parent:after {
  right: calc(0px - var(--semicircle-width) / 2)
}

.voucher-card-tile-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden; // this is needed to hide the circles created with the ::before/::after selectors
  opacity: 0.7;

  &--active,
  &--redeemed {
    @extend .voucher-card-tile-img;
  }

  &--expired {
    @extend .voucher-card-tile-img;
    opacity: 1;
    filter: grayscale(100%);
  }
}

.voucher-card-tile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
}

.voucher-card-tile-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  height: 100%;
}

.voucher-card-tile-status {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 15px;
  margin-top: 15px;
  padding: 0px 12px 0px;
  border-radius: 4px;
  opacity: 0.8;

  &-text {
    font-size: 11px;
    font-family: 'NunitoSans-Bold';
  }
}

.voucher-card-tile-name {
  font-size: 18px;
  font-family: 'NunitoSans-Bold';
  text-align: center;
}

.voucher-card-tile-logo-container {
  width: 30%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 1px dashed white;
}

.voucher-card-tile-logo {
  height: 3.5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.voucher-card-tile-logo-both {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.voucher-card-tile-icon-img {
  width: 3.5rem;
  height: 3.5rem;
  filter: var(--filter-white);
}

.voucher-card-tile-icon-img-both {
  width: 2.5rem;
  height: 2.5rem;
  filter: var(--filter-white);
}

@media screen and (max-width: 350px) {
  .voucher-card-tile-parent {
    height: 120px;
  }

  .voucher-card-tile {
    margin-bottom: 15px;
    height: 120px;
  }

  .voucher-card-tile-expiration {
    font-size: 10px;
  }

  .voucher-card-tile-name {
    font-size: 14px;
  }

  .voucher-card-tile-logo {
    height: 3rem;
    width: 3rem;
  }

  .voucher-card-tile-logo-both {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
  }

  .voucher-card-tile-icon-img-both {
    width: 2rem;
    height: 2rem;
  }
}
