:root {
  --voucher-background-min-width: 338px;
  --voucher-background-min-height: 590px;
}

.voucher-details-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
}

.voucher-details-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  padding: 30px 0 10px 0;
}

.voucher-details-header-text {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'NunitoSans-Bold';
  color: white;
  padding: 2px 10px;
  border-radius: 4px;
}

.voucher-details-header-back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.voucher-details {
  position: relative;
  min-width: var(--voucher-background-min-width);
  max-width: var(--voucher-background-min-width);
  width: var(--voucher-background-min-width);
  min-height: var(--voucher-background-min-height);
  max-height: var(--voucher-background-min-height);
  height: var(--voucher-background-min-height);
  background: url('../../../img/voucher-details.svg');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin: 40px 0 40px 0;
}

.voucher-details-info {
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 15px 0 15px;
}

.voucher-details-title {
  font-family: 'NunitoSans-Bold';
  font-size: 20px;
}

.voucher-details-subtitle {
  font-family: 'NunitoSans-Bold';
  font-size: 18px;
}

.voucher-details-text {
  font-family: 'NunitoSans-Regular';
  font-size: 13px;
}

.voucher-details-logo, .voucher-both-logo-details {
  position: absolute;
  width: 5rem;
  height: 5rem;
  z-index: 1;
  top: -2.5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.voucher-details-logo {
  background: white;
  border-style: solid;
  border-width: thick;
  border-color: white;
  border-radius: 50%;
}

.voucher-both-logo-details {
  display: flex;
  align-items: center;
  justify-content: center;

  img:first-child {
    margin-right: 10px;
  }

  img {
    border-radius: 50%;
    border: 5px solid white;
    background: white;
  }
}

.voucher-details-dashed-line-both {
  position: relative;
  top: 0;
  border-width: 1px;
  border-style: dashed;
}

.voucher-details-dashed-line {
  position: relative;
  top: 0;
  border-width: 1px;
  border-style: dashed;
}

.voucher-details-img-container {
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.voucher-details-img {
  object-fit: cover;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 325px) {
  :root {
    --voucher-background-min-width: 280px;
    --voucher-background-min-height: 490px;
  }

  .voucher-details {
    margin: 30px 0 30px 0;
  }

  .voucher-details-title {
    font-size: 16px;
  }

  .voucher-details-subtitle {
    font-size: 14px;
  }

  .voucher-details-text {
    font-size: 11px;
  }
}
