.category_service {
  padding: 1em;
  font-size: 14px;
  display: flex;
  border-bottom: 1px solid #DCDDDE;

  &_name {
    margin-bottom: auto;
    margin-top: auto;
  }
}

.category_service_btn {
  border-radius: 4px;
  border-style: solid;
  margin-left: auto;
  color: white;
  padding-left: 1em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-right: 1em;
  max-width: 30%;
  height: 40%;

  &--selected {
    background-color: rgb(103, 192, 140) !important;
  }
}
